<template>
  <b-container class="main-cont mb-3">
    <div v-if="secondQuestion.activeStep == 1">
      <b-form @submit.prevent="changeActiveStep(2)">
        <div>
          <h2 class="title">Gaat het om een koopwoning?</h2>
          <b-form-group class="mt-4">
            <b-form-radio
              required
              v-model="secondQuestion.ownHome"
              name="ownership"
              value="4587"
            >
              Ja
            </b-form-radio>
            <b-form-radio
              required
              v-model="secondQuestion.ownHome"
              name="ownership"
              value="4590"
              class="my-3"
            >
              Nee
            </b-form-radio>
          </b-form-group>
        </div>
        <div>
          <button
            type="submit"
            class="rounded-pill btnForm sm-bg-btn px-2 px-md-5 py-2 mt-2"
          >
            Volgende
          </button>
        </div>
      </b-form>
    </div>
    <!-- <div v-if="secondQuestion.activeStep == 2">
      <b-form @submit.prevent="goToNextStep">
        <div>
          <h2 class="title">Wanneer wordt u eigenaar?</h2>
          <b-form-group class="mt-4">
            <b-form-radio
              required
              v-model="secondQuestion.selectedOption.becomeOwner"
              name="ownership"
              value="Nooit, ik huur de woning"
            >
              Nooit, ik huur de woning
            </b-form-radio>
            <b-form-radio
              required
              v-model="secondQuestion.selectedOption.becomeOwner"
              name="ownership"
              value="Binnen 6 weken"
              class="my-3"
            >
              Binnen 6 weken
            </b-form-radio>
            <b-form-radio
              required
              v-model="secondQuestion.selectedOption.becomeOwner"
              name="ownership"
              value="In meer dan 6 weken"
              class="my-3"
            >
              In meer dan 6 weken
            </b-form-radio>
          </b-form-group>
        </div>
        <div>
          <button type="submit" class="rounded-pill btnForm px-5 py-2 mt-2">
            Volgende
          </button>
        </div>
      </b-form>
    </div> -->
    <!-- <div v-if="secondQuestion.activeStep == 3">
      <b-form>
        <div class="mb-5">
          <h2 class="fs--28 title">
            Helaas kunnen wij jou niet helpen, omdat je geen eigenaar bent van
            de woning.
          </h2> -->
    <!-- <div
            v-if="loader"
            class="spinner-border text-info fs--14 mt-3"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div> -->
    <!-- </div>
      </b-form>
    </div> -->
  </b-container>
</template>

<script>
export default {
  name: "SecondQuestion",
  data() {
    return {
      loader: false,
    };
  },
  props: {
    secondQuestion: {
      type: Object,
    },
    thirdQuestion: {
      type: Object,
    },
    step: {
      type: Number,
    },
  },
  methods: {
    changeActiveStep(step) {
      console.log("selected option..", this.secondQuestion);
      this.$emit("gotoStepThree", this.step + 1);
      // if (this.secondQuestion.selectedOption.ownHome == "4394") {
      //   this.$emit("gotoStepThree", this.step + 1);
      // }
      // if (this.secondQuestion.selectedOption.ownHome == "4397") {
      //   this.secondQuestion.activeStep = 2;
      // }
    },
    // goToNextStep() {
    //   if (
    //     this.secondQuestion.selectedOption.becomeOwner ==
    //     "Nooit, ik huur de woning"
    //   ) {
    //     this.secondQuestion.activeStep = 3;
    //     this.thirdQuestion.loader = false;
    //   } else {
    //     this.$emit("gotoStepThree", this.step + 1);
    //     this.thirdQuestion.activeStep == 1;
    //   }
    // },
  },
  // computed: {
  //   activeStep() {
  //     return this.secondQuestion.activeStep;
  //   },
  // },
  // watch: {
  //   activeStep: function (step) {
  //     setTimeout(() => {
  //       if (step === 3) {
  //         this.$emit("gotoStepThree", this.step + 1);
  //       }
  //     }, 2000);
  //   },
  // },
  mounted() {
    this.loader = true;
    setTimeout(() => {
      if (this.secondQuestion.activeStep === 3) {
        this.$emit("gotoStepThree", this.step + 1);
        this.loader = false;
      }
    }, 2000);
  },
};
</script>

<style scoped></style>
