var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center map-section"},[_c('GmapMap',{staticStyle:{"width":"100%","height":"100%","border-radius":"100px"},attrs:{"options":{
        zoomControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: true,
        rotateControl: true,
        disableDefaultUi: false,
      },"center":{
        lat: this.lat ? this.lat : 0,
        lng: this.lng ? this.lng : 0,
      },"zoom":18,"map-type-id":"hybrid"}},[_c('GmapMarker',{attrs:{"position":{
          lat: this.lat ? this.lat : 0,
          lng: this.lng ? this.lng : 0,
        },"clickable":true,"draggable":true}})],1),_c('div',{staticClass:"d-flex justify-content-center align-items-center question-card__right-col-text position-absolute"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }