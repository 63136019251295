<template>
  <div>
    <ul class="banner__ul__styling">
      <li class="banner__ul__text">
        <i class="fa-solid fa-check banner__icoColor mr-1"></i>Gratis
        <b> airco offertes </b> vergelijken
      </li>
      <li class="banner__ul__text">
        <i class="fa-solid fa-check banner__icoColor mr-1"></i>Bespaar op je
        <b> gasrekening! </b>
      </li>
      <li class="banner__ul__text">
        <i class="fa-solid fa-check banner__icoColor mr-1"></i
        ><b>Gratis,</b> snel en gemakkelijk
      </li>
      <li class="banner__ul__text">
        <i class="fa-solid fa-check banner__icoColor mr-1"></i>Verhoogt het
        <b> wooncomfort </b>
      </li>
      <li class="banner__ul__text">
        <i class="fa-solid fa-check banner__icoColor mr-1"></i
        ><b>Binnen 1 dag</b> geregeld
      </li>
      <li class="banner__ul__text">
        <i class="fa-solid fa-check banner__icoColor mr-1"></i>Meer dan
        <span class="banner__icoColor font-weight-bold">109.000</span>
        mensen gingen je voor
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CheckList",
};
</script>

<style lang="scss" scoped>
.banner {
  &__ul {
    border-radius: 8px !important;
    border-left: 2px solid #eee !important;
    border-color: #74a200 !important;
  }
  &__icoColor {
    color: #74a200;
  }
  &__ul__styling {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  &__ul__text {
    // font-weight: 700;
    margin-top: 7px !important;
    font-size: 15px;
  }
}
</style>