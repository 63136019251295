<template>
  <b-container fluid class="hero-section">
    <b-row>
      <b-col>
        <FirstForm @show-second-form="toggleForm" v-if="!showSecondForm" />
        <SecondForm v-else />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import FirstForm from "./FirstForm.vue";
import SecondForm from "./SecondForm.vue";

export default {
  name: "HeroSection",
  components: {
    FirstForm,
    SecondForm,
  },
  props: {
    showSecondForm: Boolean,
  },
  //   data() {
  //     return {
  //       showSecondForm: false,
  //     };
  //   },
  methods: {
    toggleForm() {
      this.$emit("changeState", !this.showSecondForm);
    },
  },
};
</script>

<style lang="scss" scoped>
.hero-section {
  background: url(../../assets/isolatie-hero-bg.png) no-repeat;
  background-size: cover;
}
</style>
