<template>
  <b-container class="py-4">
    <b-row>
      <b-Col class="col-lg-6 col-md-9">
        <div class="p-4 form">
          <h1 class="text-size fw--700">Zonnepanelen nu extreem goedkoop!</h1>

          <p class="fs--16 fw--500 d-md-block d-none">
            <span class="fw--700 cursor-pointer"
              >De prijs van zonnepanelen is gekelderd.</span
            >
            Profiteer nu in 2024 van alle voordelen en start ook met besparen.
            Wist je dat je nu zelfs tot
            <span
              @click="showSecondForm"
              class="fw--700 cursor-pointer text__red"
              >40% extra korting
            </span>
            kunt ontvangen?
          </p>
          <p class="fs--16 fw--500 d-md-none d-block">
            <span class="fw--700 cursor-pointer"
              >De prijs van zonnepanelen is gekelderd.</span
            >
            Wist je dat je nu zelfs tot
            <span
              @click="showSecondForm"
              class="fw--700 cursor-pointer text__red"
              >40% extra korting
            </span>
            kunt ontvangen?
          </p>

          <p class="fs--16 fw--500 mt-3 d-md-block d-none">
            Met zonnepanelen bespaar je duizenden euro’s op jouw
            energierekening, ze gaan wel 25 jaar mee én jouw woning wordt meer
            waard!
          </p>
          <p class="fs--16 fw--500 mt-3 mb-1">
            ✅Korte terugverdientijd: 6 jaar
          </p>
          <p class="fs--16 fw--500 mb-1">✅Gratis advies op maat</p>

          <p class="fs--16 fw--500">✅Nul % BTW</p>

          <p
            class="fs--16 fw--700 d-md-block d-none cursor-pointer"
            @click="showSecondForm"
          >
            Ontvang de beste deals!
          </p>

          <p class="fs--16 fw--500 d-none d-md-block">
            Ontdek nu met onze
            <span @click="showSecondForm" class="fw--700 cursor-pointer"
              >gratis check</span
            >
            de mogelijkheden voor jouw woning! Ontvang
            <span @click="showSecondForm" class="fw--700 cursor-pointer"
              >gratis offertes</span
            >
            van de beste lokale specialisten en vind de installateur die het
            beste bij je past!
          </p>

          <p class="fs--16 fw--500 mb-0 d-md-none d-block">
            Ontdek nu met onze
            <span @click="showSecondForm" class="fw--700 cursor-pointer"
              >gratis check</span
            >
            de mogelijkheden voor jouw woning!
          </p>
          <p class="fs--16 fw--500 mt-2 d-none">
            Ontvang
            <span @click="showSecondForm" class="fw--700 cursor-pointer"
              >gratis offertes</span
            >
            van de beste lokale specialisten en vind de installateur die het
            beste bij je past!
          </p>

          <button
            class="rounded-pill btnForm gratis-sm-btn px-4 py-2 fw--700 mt-md-0 mt-3"
            @click="showSecondForm"
          >
            Gratis check
          </button>
        </div>
      </b-Col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "FirstForm",
  methods: {
    showSecondForm() {
      this.$emit("show-second-form");
    },
  },
};
</script>

<style lang="scss">
.form {
  background-color: #ffffff;
  border-radius: 0.25rem !important;
  @media (max-width: 600px) {
    border-radius: 20px !important;
  }
}

.title-color {
  color: #86b37e;
}
.text-size {
  @media (max-width: 992px) {
    font-size: 30px;
  }

  @media (max-width: 768px) {
    font-size: 25px;
  }

  @media (max-width: 519px) {
    // font-size: 33px;
    font-size: 22px;
  }

  // @media (max-width: 384px) {
  //   font-size: 25px;
  // }

  // @media (max-width: 414px) {
  //   font-size: 22px;
  // }

  @media (max-width: 340px) {
    font-size: 20px;
  }
}

.btnForm {
  background-color: #f8b748;
  border: 1px solid #f8b748;
  color: #ffffff;
  width: auto;
  @media (max-width: 567.98px) {
    width: 100%;
  }
}

.underline {
  text-decoration: underline !important;
}
</style>
