<template>
  <b-container fluid class="footer">
    <b-container class="px-5 mid-padding">
      <b-row class="p-5 text-center">
        <div class="col-md-6">
          <p class="text-light">© Copyright 2023. Offertevergelijker.nl</p>
        </div>
        <div class="col-md-6">
          <div class="text-light">
            <span class="cursor-pointer" v-b-modal.PrivacyPolicy>
              Privacyverklaring &nbsp; </span
            ><span>|</span
            ><span class="cursor-pointer" v-b-modal.TermsConditions>
              &nbsp; Algemene voorwaarden</span
            >
            <!-- <a>Privacyverklaring</a>&nbsp;&&nbsp;<a>Algemene voorwaarden</a> -->
          </div>
        </div>

        <div>
          <b-modal
            id="PrivacyPolicy"
            centered
            title="Privacy statement"
            ok-only
            size="lg"
            ok-variant="success"
            scrollable
          >
            <div class="px-3">
              <p>
                Wij zijn er van bewust dat u vertrouwen stelt in ons. Wij zien
                het dan ook als onze verantwoordelijkheid om uw privacy te
                beschermen. Op deze pagina laten we u weten welke gegevens we
                verzamelen als u onze website gebruikt, waarom we deze gegevens
                verzamelen en hoe we hiermee uw gebruikservaring verbeteren. Zo
                snapt u precies hoe wij werken.
              </p>
              <p>
                Dit privacybeleid is van toepassing op de diensten van
                www.Offertevergelijker.nl. U dient zich ervan bewust te zijn dat
                www.Offertevergelijker.nl niet verantwoordelijk is voor het
                privacybeleid van andere sites en bronnen. Door gebruik te maken
                van deze website geeft u aan het privacy beleid te accepteren.
              </p>
              <p>
                www.Offertevergelijker.nl respecteert de privacy van alle
                gebruikers van haar site en draagt er zorg voor dat de
                persoonlijke informatie die u ons verschaft vertrouwelijk wordt
                behandeld.
              </p>
              <h4>Ons gebruik van verzamelde gegevens</h4>
              <h4>Gebruik van onze diensten</h4>
              <p>
                Wanneer u zich aanmeldt voor een van onze diensten vragen we u
                om persoonsgegevens te verstrekken. Deze gegevens worden
                gebruikt om de dienst uit te kunnen voeren. De gegevens worden
                opgeslagen op eigen beveiligde servers van
                www.Offertevergelijker.nl of die van een derde partij. Wij
                zullen deze gegevens niet combineren met andere persoonlijke
                gegevens waarover wij beschikken.
              </p>
              <h4>Communicatie</h4>
              <p>
                Wanneer u e-mail of andere berichten naar ons verzendt, is het
                mogelijk dat we die berichten bewaren. Soms vragen wij u naar uw
                persoonlijke gegevens die voor de desbetreffende situatie
                relevant zijn. Dit maakt het mogelijk uw vragen te verwerken en
                uw verzoeken te beantwoorden. De gegevens worden opgeslagen op
                eigen beveiligde servers van www.Offertevergelijker.nl of die
                van een derde partij. Wij zullen deze gegevens niet combineren
                met andere persoonlijke gegevens waarover wij beschikken.
              </p>
              <h4>Cookies</h4>
              <p>
                Wij verzamelen gegevens voor onderzoek om zo een beter inzicht
                te krijgen in onze klanten, zodat wij onze diensten hierop
                kunnen afstemmen.
              </p>
              <p>
                Deze website maakt gebruik van cookies (tekstbestandtjes die op
                uw computer worden geplaatst) om de website te helpen analyseren
                hoe gebruikers de site gebruiken. De door het cookie
                gegenereerde informatie over uw gebruik van de website kan
                worden overgebracht naar eigen beveiligde servers van
                www.Offertevergelijker.nl of die van een derde partij. Wij
                gebruiken deze informatie om bij te houden hoe u de website
                gebruikt, om rapporten over de website-activiteit op te stellen
                en andere diensten aan te bieden met betrekking tot
                website-activiteit en internetgebruik.
              </p>
              <h4>Doeleinden</h4>
              <p>
                We verzamelen of gebruiken geen informatie voor andere
                doeleinden dan de doeleinden die worden beschreven in dit
                privacybeleid tenzij we van tevoren uw toestemming hiervoor
                hebben verkregen.
              </p>
              <h4>Derden</h4>
              <p>
                De informatie wordt niet met derden gedeeld. In enkele gevallen
                kan de informatie intern gedeeld worden. Onze werknemers zijn
                verplicht om de vertrouwelijkheid van uw gegevens te
                respecteren.
              </p>
              <h4>Veranderingen</h4>
              <p>
                Deze privacyverklaring is afgestemd op het gebruik van en de
                mogelijkheden op deze site. Eventuele aanpassingen en/of
                veranderingen van deze site, kunnen leiden tot wijzigingen in
                deze privacyverklaring. Het is daarom raadzaam om regelmatig
                deze privacyverklaring te raadplegen.
              </p>
              <h4>Keuzes voor persoonsgegevens</h4>
              <p>
                Wij bieden alle bezoekers de mogelijkheid tot het inzien,
                veranderen, of verwijderen van alle persoonlijke informatie die
                op moment aan ons is verstrekt.
              </p>
              <p>Aanpassen/uitschrijven dienst nieuwsbrief</p>
              <p>
                Onderaan iedere mailing vindt u de mogelijkheid om uw gegevens
                aan te passen of om u af te melden.
              </p>
              <p>Aanpassen/uitschrijven communicatie</p>
              <p>
                Als u uw gegevens aan wilt passen of uzelf uit onze bestanden
                wilt laten halen, kunt u contact met ons op nemen. Zie
                onderstaande contactgegevens.
              </p>
              <h4>Cookies uitzetten</h4>
              <p>
                De meeste browsers zijn standaard ingesteld om cookies te
                accepteren, maar u kunt uw browser opnieuw instellen om alle
                cookies te weigeren of om aan te geven wanneer een cookie wordt
                verzonden. Het is echter mogelijk dat sommige functies en
                services, op onze en andere websites, niet correct functioneren
                als cookies zijn uitgeschakeld in uw browser.
              </p>
              <h4>Vragen en feedback</h4>
              <p>
                We controleren regelmatig of we aan dit privacybeleid voldoen.
                Als u vragen heeft over dit privacybeleid, kunt u contact met
                ons opnemen:
              </p>
              <p>
                Offertevergelijker.nl<br />Industrieweg 10<br />4131NK Vianen<br /><a
                  href="mailto:info@offertevergelijker.nl"
                  class="text-secondary"
                  >info@offertevergelijker.nl</a
                >
              </p>
            </div>
          </b-modal>
          <b-modal
            id="TermsConditions"
            centered
            title="Algemene voorwaarden"
            ok-only
            size="lg"
            ok-variant="success"
            scrollable
          >
            <div class="px-3">
              <p>
                Via Offertevergelijker.nl vragen dagelijks vele Nederlanders
                offertes aan voor zijn of haar klus. Nadat je een
                offerteaanvraag hebt doorgestuurd via één van onze formulieren,
                bekijken wij de aanvraag en indien deze akkoord is, zoeken wij
                maximaal drie bedrijven waarvan wij denken dat ze u een goede
                aanbieding kunnen doen. Het aanvragen van offertes is gratis en
                vrijblijvend.
              </p>
              <h4>Persoonsgegevens</h4>
              <p>
                Om een offerteaanvraag door te kunnen zetten naar relevante
                partners, hebben wij enkele persoonsgegevens van u nodig. Onder
                persoonsgegevens verstaan wij ieder gegeven dat je aan ons
                doorgeeft, waarmee jij als persoon aanwijsbaar bent. Voorbeelden
                van persoonsgegevens zijn je naam en adres.
              </p>
              <p>
                Wij vragen tevens om een telefoonnummer. Deze vragen wij, omdat
                de geselecteerde bedrijven bij jouw aanvraag in sommige gevallen
                aanvullende vragen hebben. Zo kunnen zij de offerte zo op maat
                mogelijk maken en krijgt u het beste overzicht van de
                mogelijkheden.
              </p>
              <h4>Geen garantie</h4>
              <p>
                Bij Offertevergelijker brengen wij particulieren en bedrijven
                bij elkaar. De rol van Offertevergelijker.nl gaat nooit verder
                dan het faciliteren van vraag en aanbod. Wij geven geen garantie
                op de geleverde diensten of uitgevoerde werkzaamheden.
                Offertevergelijker.nl is dan ook niet aansprakelijk voor
                eventuele schade door of onwenselijk gedrag van het uitvoerende
                bedrijf. U blijft te allen tijde zelf verantwoordelijk voor het
                vastleggen van goede afspraken met het bedrijf dat uw
                werkzaamheden uitvoert.
              </p>
              <h4>Wijzigingen algemene voorwaarden</h4>
              <p>
                Offertevergelijker heeft het recht om de informatie op deze
                website aan te passen en kan dit doen zonder hiervan enige
                kennisgeving daarvan.
              </p>
              <p>
                Door onze website te bezoeken, ga je automatisch akkoord met
                deze algemene voorwaarden.
              </p>
            </div>
          </b-modal>
        </div>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: #3d99cd;
}

.mid-padding {
  @media (max-width: 768px) {
    padding: 0px !important;
  }
}
</style>
