<template>
  <b-container class="py-4">
    <b-row>
      <b-Col class="col-lg-6 col-md-9">
        <div class="p-4 form">
          <h1 class="text-size heading-isolatie fw--700 d-none d-md-block">
            Ontdek de voordelen van woningisolatie
          </h1>
          <!-- <h1 class="title-color text-size fw--700 d-none d-md-block">
            Tot 30%
          </h1> -->
          <div class="d-block d-md-none">
            <span class="text-size fw--700 mr-1"
              >Ontdek de voordelen van woningisolatie</span
            >
            <!-- <span class="title-color text-size fw--700">Tot 30%</span> -->
          </div>
          <p class="fs--16 fw--500 d-md-block d-none">
            Woning isoleren? Goed plan, de overheid geeft namelijk honderden
            miljoenen aan subsidies weg.
            <span class="fw--700 title-color">
              Huizenbezitters ontvangen nu tot wel 30% subsidie.</span
            >
          </p>
          <p class="fs--16 fw--500 d-md-none d-block">
            Woning isoleren? Goed plan, de overheid geeft namelijk honderden
            miljoenen aan subsidies weg.
            <span class="fw--700 title-color">
              Huizenbezitters ontvangen nu tot wel 30% subsidie.</span
            >
          </p>
          <!-- <div class="row align-items-center my-1 my-md-3">
            <div class="col-12 col-md-12 result-desc mb-3 mb-md-0">
              <div class="d-flex"> -->
          <!-- <span>
                  <img
                    src="../../assets/Ellipse.png"
                    class="result-desc__img"
                    width="25px"
                  />
                </span> -->
          <!-- <span class="align-self-center">
                  <p class="ml-1 mb-0 d-md-block d-none">
                    Tot 30% overheidssubsidie;
                  </p>
                  <p class="ml-1 mb-0 d-md-none d-block">
                    Tot 30% overheidssubsidie;
                  </p>
                </span> -->
          <!-- </div>
            </div>
          </div> -->
          <div class="row align-items-center my-1 my-md-3">
            <div class="col-12 col-md-12 result-desc mb-3 mb-md-0">
              <div class="d-flex">
                <!-- <span>
                  <img
                    src="../../assets/Ellipse.png"
                    class="result-desc__img"
                    width="25px"
                  />
                </span> -->
                <span class="align-self-center">
                  <p class="ml-1 mb-0 d-md-block d-none">
                    ✅ Korte terugverdientijd: minder dan 3 jaar;
                  </p>
                  <p class="ml-1 mb-0 d-md-none d-block">
                    ✅ Korte terugverdientijd: minder dan 3 jaar;
                  </p>
                </span>
              </div>
            </div>
          </div>
          <div class="row align-items-center my-1 my-md-3">
            <div class="col-12 col-md-12 result-desc mb-3 mb-md-0">
              <div class="d-flex">
                <!-- <span>
                  <img
                    src="../../assets/Ellipse.png"
                    class="result-desc__img"
                    width="25px"
                  />
                </span> -->
                <span class="align-self-center">
                  <p class="ml-1 mb-0 d-md-block d-none">
                    ✅ Lagere stookkosten;
                  </p>
                  <p class="ml-1 mb-0 d-md-none d-block">
                    ✅ Lagere stookkosten;
                  </p>
                </span>
              </div>
            </div>
          </div>
          <div class="row align-items-center my-1 my-md-3">
            <div class="col-12 col-md-12 result-desc mb-3 mb-md-0">
              <div class="d-flex">
                <!-- <span>
                  <img
                    src="../../assets/Ellipse.png"
                    class="result-desc__img"
                    width="25px"
                  />
                </span> -->
                <span class="align-self-center">
                  <p class="ml-1 mb-0 d-md-block d-none">
                    ✅ Minder geluidsoverlast;
                  </p>
                  <p class="ml-1 mb-0 d-md-none d-block">
                    ✅ Minder geluidsoverlast;
                  </p>
                </span>
              </div>
            </div>
          </div>
          <div class="row align-items-center my-1 my-md-3">
            <div class="col-12 col-md-12 result-desc mb-3 mb-md-0">
              <div class="d-flex">
                <!-- <span>
                  <img
                    src="../../assets/Ellipse.png"
                    class="result-desc__img"
                    width="25px"
                  />
                </span> -->
                <span class="align-self-center">
                  <p class="ml-1 mb-0 d-md-block d-none">
                    ✅ Woning wordt meer waard.
                  </p>
                  <p class="ml-1 mb-0 d-md-none d-block">
                    ✅ Woning wordt meer waard.
                  </p>
                </span>
              </div>
            </div>
          </div>

          <!-- <p class="fs--16 fw--500 d-block d-md-none mt-3">
            Woning isoleren? Goed plan, zeker nu!
            <span class="fw--700 underline"
              >Huizenbezitters ontvangen nu tot wel 30% subsidie.</span
            >
          </p> -->
          <!-- <p class="fs--16 fw--500 d-md-block d-none">
            Met isoleren bespaar je al snel vele honderden euro's op jouw
            energiekosten. De gemiddelde
            <span class="fw--700">terugverdientijd</span> voor isolatie is nu
            zelfs <span class="fw--700">korter dan 3 jaar.</span>
          </p> -->
          <!-- <p class="fs--16 fw--500 d-md-none d-block">
            De gemiddelde
            <span class="fw--700">terugverdientijd</span> voor isolatie is nu
            zelfs <span class="fw--700">korter dan 3 jaar.</span>
          </p> -->
          <p class="fs--16 fw--500 d-md-block d-none">
            Bespaar tot 30% extra (bovenop de subsidie) met onze gratis check.
            Zo ontvang jij gratis offertes van de beste lokale specialisten en
            vind de installateur die het beste bij je past!
          </p>
          <p class="fs--16 fw--500 d-block d-md-none">
            Bespaar tot 30% extra (bovenop de subsidie) met onze gratis check.
            Zo ontvang jij gratis offertes van de beste lokale specialisten en
            vind de installateur die het beste bij je past!
          </p>

          <button
            class="rounded-pill btnForm px-4 py-2 fw--700"
            @click="showSecondForm"
          >
            Gratis woningcheck
          </button>
        </div>
      </b-Col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "FirstForm",
  methods: {
    showSecondForm() {
      this.$emit("show-second-form");
    },
  },
};
</script>

<style lang="scss">
.heading-isolatie {
  font-family: "Montserrat";
}
.form {
  background-color: #ffffff;
  border-radius: 0.25rem !important;
  @media (max-width: 600px) {
    border-radius: 20px !important;
  }
}

.title-color {
  color: #86b37e;
}
.text-size {
  @media (max-width: 992px) {
    font-size: 30px;
  }

  @media (max-width: 768px) {
    font-size: 25px;
  }

  @media (max-width: 519px) {
    font-size: 33px;
  }

  @media (max-width: 384px) {
    font-size: 25px;
  }

  @media (max-width: 414px) {
    font-size: 22px;
  }

  @media (max-width: 340px) {
    font-size: 20px;
  }
}

.btnForm {
  background-color: #f8b748;
  border: 1px solid #f8b748;
  color: #ffffff;
  width: auto;
  @media (max-width: 567.98px) {
    width: 100%;
  }
}

.underline {
  text-decoration: underline !important;
}
</style>
