<template>
  <b-container class="main-cont mb-3">
    <b-form class="mt-3" @submit.prevent="goToNextStep">
      <div>
        <h2 class="title">In welk jaar is de woning gebouwd?</h2>
        <b-form-group class="mt-4">
          <b-form-radio
            required
            v-model="forthQuestion.houseBuilt"
            name="ownership"
            value="4421"
          >
            Voor 1920
          </b-form-radio>
          <b-form-radio
            required
            v-model="forthQuestion.houseBuilt"
            name="ownership"
            value="4424"
            class="my-3"
          >
            Tussen 1970 > 1976
          </b-form-radio>
          <b-form-radio
            required
            v-model="forthQuestion.houseBuilt"
            name="ownership"
            value="4427"
            class="my-3"
          >
            Tussen 2000 > 1991
          </b-form-radio>
          <b-form-radio
            required
            v-model="forthQuestion.houseBuilt"
            name="ownership"
            value="4430"
            class="my-3"
          >
            Na 2000
          </b-form-radio>
        </b-form-group>
      </div>
      <div>
        <button type="submit" class="rounded-pill btnForm px-5 py-2 mt-2">
          {{
            thirdQuestion.selectedOption.includes("Dak")
              ? " Volgende"
              : "Volgende"
          }}
        </button>
      </div>
    </b-form>
  </b-container>
</template>

<script>
export default {
  name: "ForthQuestion",
  props: {
    step: {
      type: Number,
    },
    forthQuestion: {
      type: Object,
    },
    thirdQuestion: {
      type: Object,
    },
    fifthQuestion: {
      type: Object,
    },
  },
  methods: {
    nextQuestion() {
      this.thirdQuestion.activeStep = 2;
      this.thirdQuestion.loader = true;
      setTimeout(() => {
        if (this.thirdQuestion.activeStep === 2) {
          this.thirdQuestion.activeStep = 3;
          this.thirdQuestion.loader = false;
        }
      }, 2000);
    },

    // id="spouwmuur"
    // value="4418"

    // id="dak"
    //         value="4409"
    //         id="vloer"
    //         value="4415"
    //         id="kozijnen"
    //         value="4412"

    goToNextStep() {
      console.log(this.forthQuestion.houseBuilt, "Forth question");
      if (
        this.thirdQuestion.selectedOption.includes("4579") ||
        this.thirdQuestion.selectedOption.includes("4576")
      ) {
        if (this.thirdQuestion.selectedOption.includes("4563")) {
          this.$emit("gotoStepThree", this.step - 1);
          this.thirdQuestion.activeStep = 3;
        } else if (this.thirdQuestion.selectedOption.includes("4566")) {
          this.fifthQuestion.activeStep = false;
          this.$emit("gotoStepFifth", this.step + 1);
        } else {
          this.$emit("gotoStepFifth", this.step + 2);
        }
      } else if (this.thirdQuestion.selectedOption.includes("4563")) {
        this.$emit("gotoStepThree", this.step - 1);
        this.thirdQuestion.activeStep = 3;
      } else if (this.thirdQuestion.selectedOption.includes("4566")) {
        this.fifthQuestion.activeStep = false;
        this.$emit("gotoStepFifth", this.step + 1);
      }
    },

    // goToNextStep() {
    //     if (this.thirdQuestion.selectedOption.includes('4409')) {
    //         this.$emit('gotoStepFifth', this.step + 1);
    //     } else {
    //         this.$emit('gotoStepFifth', this.step + 2);
    //     }
    // }
  },
};
</script>

<style lang="scss" scoped></style>
