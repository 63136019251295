<template>
  <div class="d-flex flex-column">
    <div class="d-flex justify-content-center align-items-center map-section">
      <GmapMap
        :options="{
          zoomControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: true,
          rotateControl: true,
          disableDefaultUi: false,
        }"
        :center="{
          lat: this.lat ? this.lat : 0,
          lng: this.lng ? this.lng : 0,
        }"
        :zoom="18"
        map-type-id="hybrid"
        style="width: 100%; height: 100%; border-radius: 100px"
      >
        <GmapMarker
          :position="{
            lat: this.lat ? this.lat : 0,
            lng: this.lng ? this.lng : 0,
          }"
          :clickable="true"
          :draggable="true"
        />
        <!-- <StreetViewPanorama
        :position="{
          lat: location.lat,
          lng: location.lng,
        }"
        :pov="{
          heading: 0,
          pitch: 0,
        }"
        :zoom="1"
      /> -->
      </GmapMap>
      <!-- <span>Walter gropiuserf 6, Amersfoort</span> -->
      <div
        class="d-flex justify-content-center align-items-center question-card__right-col-text position-absolute"
      >
        <!-- <span v-if="address.results && address.results.length > 0">{{
        address?.results[0]?.formatted_address
      }}</span> -->
      </div>
      <!-- <span>{{ address?.results[0]?.formatted_address }}</span> -->
    </div>
  </div>
</template>
<script>
import { StreetViewPanorama } from "vue2-google-maps";
import { mapGetters } from "vuex";
export default {
  name: "GoogleMapSection",
  data() {
    return {
      center: { lat: 37.7749, lng: -122.4194 },
      zoom: 12,
    };
  },
  components: {
    StreetViewPanorama,
  },
  computed: {
    ...mapGetters(["lat", "lng", "address"]),
  },
};
</script>
<style lang="scss" scoped>
.map-section {
  height: 200px !important;
  width: 100% !important;
  //   border: 5px solid red !important;
  border-radius: 15px !important;
  overflow: hidden !important;
  @media (max-width: 768px) {
    height: 83px !important;
  }
}
</style>
