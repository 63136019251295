<template>
  <div>
    <h4 class="font-weight-bold text-center">
      Ontvang
      <span class="text-purple"><u> gratis offertes voor een airco </u> </span>
      en vergelijk tot 4 bedrijven die actief zijn in jouw regio!
    </h4>
    <div class="my-4">
      <b-form @submit.prevent="onSubmit">
        <!--  -->
        <input type="checkbox" class="hidden-checkbox" v-model="bootCheck" />
        <b-row>
          <div class="col-12 col-md-6">
            <b-form-group id="fName" label="Voornaam" label-for="fName">
              <b-form-input
                :class="
                  this.getErrorResponses.firstname == false ? 'is-invalid' : ''
                "
                id="fName"
                v-model="$v.second_form.firstname.$model"
                :state="validateState('firstname')"
                @input="handleFieldInput('firstname')"
                placeholder="bv. Pieter"
                required
              ></b-form-input>
              <b-form-invalid-feedback
                v-if="
                  !$v.second_form.firstname.required ||
                  !getErrorResponses.firstname
                "
              >
                Voornaam niet correct
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-12 col-md-6">
            <b-form-group id="lName" label="Achternaam" label-for="lName">
              <b-form-input
                :class="
                  this.getErrorResponses.lastname === false ? 'is-invalid' : ''
                "
                id="lName"
                v-model="$v.second_form.lastname.$model"
                :state="validateState('lastname')"
                @input="handleFieldInput('lastname')"
                placeholder="bv. de Jong"
                required
              ></b-form-input>
              <b-form-invalid-feedback
                v-if="
                  !$v.second_form.lastname.required ||
                  !getErrorResponses.lastname
                "
              >
                Achternaam niet correct
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </b-row>
        <b-row>
          <div class="col-12 col-md-6">
            <b-form-group id="tel" label="Telefoonnummer" label-for="tel">
              <b-form-input
                :class="
                  this.getErrorResponses.phone_number == false
                    ? 'is-invalid'
                    : ''
                "
                id="tel"
                type="tel"
                v-model="$v.second_form.phone_number.$model"
                :state="validateState('phone_number')"
                @input="handleFieldInput('phone_number')"
                placeholder="bv. 0612345678"
                required
              ></b-form-input>
              <b-form-invalid-feedback
                v-if="
                  !$v.second_form.phone_number.required ||
                  !getErrorResponses.phone_number
                "
              >
                Telefoonnummer niet correct
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-12 col-md-6">
            <label for="email">E-mail</label>
            <b-form-input
              :class="
                this.getErrorResponses.email == false &&
                this.second_form.email !== ''
                  ? 'is-invalid'
                  : ''
              "
              v-model="second_form.email"
              :state="validateState('email')"
              @input="handleFieldInput('email')"
              type="email"
              id="email"
              placeholder="bv. piet@dejong.nl"
            ></b-form-input>
            <b-form-invalid-feedback
              v-if="!$v.second_form.email.required || !getErrorResponses.email"
            >
              E-mailadres niet correct
            </b-form-invalid-feedback>
          </div>
        </b-row>

        <b-row>
          <div class="col-12 mt-md-0 mt-1">
            <label for="roof"
              >Hoeveel In hoeveel ruimtes moet de airco komen?</label
            >
            <div class="row no-gutters">
              <label class="btn btn-lg col p-0 m-0"
                ><input
                  class="btnDiv"
                  value="4006"
                  v-model="hoeveel"
                  type="radio"
                  name="roof"
                  required
                /><span class="btnDiv h1 mr- mr-md- font-weight-bold">
                  1
                </span></label
              >
              <label class="btn btn-lg col pl-1 pl-md-2 m-0"
                ><input
                  value="4009"
                  v-model="hoeveel"
                  type="radio"
                  name="roof"
                  required
                /><span class="btnDiv h1 font-weight-bold"> 2 </span></label
              >
              <label class="btn btn-lg col pl-1 pl-md-2 m-0"
                ><input
                  value="3"
                  v-model="hoeveel"
                  type="radio"
                  name="roof"
                  required
                /><span class="btnDiv h1 font-weight-bold"> 3+ </span></label
              >
              <!-- <label class="btn btn-lg col pl-1 pl-md-2 m-0"
                ><input
                  value="4"
                  v-model="hoeveel"
                  type="radio"
                  name="roof"
                  required
                /><span class="btnDiv h1 font-weight-bold"> 4 </span></label
              >
              <label class="btn btn-lg col pl-1 pl-md-2 m-0"
                ><input
                  value="5"
                  v-model="hoeveel"
                  type="radio"
                  name="roof"
                  required
                /><span class="btnDiv h1 font-weight-bold"> 5+ </span></label
              > -->
            </div>
          </div>
        </b-row>

        <b-row>
          <div class="col-12">
            <label for="oper" class="mt-3"
              >Wat is de oppervlakte van de ruimtes?</label
            >
          </div>

          <div class="col-12 col-md-6">
            <b-form-checkbox
              id="checkbox-1"
              v-model="activeWeetIkNiet"
              name="checkbox-1"
              value="true"
              unchecked-value="not_accepted"
            >
              Weet ik niet
            </b-form-checkbox>
            <div class="d-flex align-items-center">
              <b-form-input
                type="text"
                v-model="weetIkNiet"
                :disabled="activeWeetIkNiet == 'true'"
                :required="activeWeetIkNiet == 'true'"
                class="mt-2"
              ></b-form-input>
              <span class="ml-1">m2</span>
            </div>
          </div>
        </b-row>

        <b-col lg="12" class="py-4 px-0 mt-md-2 mt-1"
          ><b-button
            size="lg"
            type="submit"
            variant="success"
            class="w-100 py-3"
            style="background: #759e62; border: #74a200"
          >
            <b-spinner v-if="getLoader" /><span v-else
              ><b> Gratis offertes ontvangen ></b></span
            ></b-button
          ></b-col
        >
      </b-form>
      <div class="text-center">
        <small class="text-muted font11">
          Door vrijblijvend offertes aan te vragen ga je akkoord met onze
          <span class="cursor-pointer" v-b-modal.Algemene>
            Algemene voorwaarden.</span
          >
        </small>
      </div>
    </div>
    <!-- modal -->
    <b-modal
      id="Algemene"
      centered
      title="Algemene voorwaarden"
      ok-only
      size="lg"
      ok-variant="success"
      scrollable
    >
      <div>
        <p>
          Via Offertevergelijker.nl vragen dagelijks vele Nederlanders offertes
          aan voor zijn of haar klus. Nadat je een offerteaanvraag hebt
          doorgestuurd via één van onze formulieren, bekijken wij de aanvraag en
          indien deze akkoord is, zoeken wij maximaal drie bedrijven waarvan wij
          denken dat ze u een goede aanbieding kunnen doen. Het aanvragen van
          offertes is gratis en vrijblijvend.
        </p>
        <h4>Persoonsgegevens</h4>
        <p>
          Om een offerteaanvraag door te kunnen zetten naar relevante partners,
          hebben wij enkele persoonsgegevens van u nodig. Onder persoonsgegevens
          verstaan wij ieder gegeven dat je aan ons doorgeeft, waarmee jij als
          persoon aanwijsbaar bent. Voorbeelden van persoonsgegevens zijn je
          naam en adres.
        </p>
        <p>
          Wij vragen tevens om een telefoonnummer. Deze vragen wij, omdat de
          geselecteerde bedrijven bij jouw aanvraag in sommige gevallen
          aanvullende vragen hebben. Zo kunnen zij de offerte zo op maat
          mogelijk maken en krijgt u het beste overzicht van de mogelijkheden.
        </p>
        <h4>Geen garantie</h4>
        <p>
          Bij Offertevergelijker brengen wij particulieren en bedrijven bij
          elkaar. De rol van Offertevergelijker.nl gaat nooit verder dan het
          faciliteren van vraag en aanbod. Wij geven geen garantie op de
          geleverde diensten of uitgevoerde werkzaamheden. Offertevergelijker.nl
          is dan ook niet aansprakelijk voor eventuele schade door of
          onwenselijk gedrag van het uitvoerende bedrijf. U blijft te allen
          tijde zelf verantwoordelijk voor het vastleggen van goede afspraken
          met het bedrijf dat uw werkzaamheden uitvoert.
        </p>
        <h4>Wijzigingen algemene voorwaarden</h4>
        <p>
          Offertevergelijker heeft het recht om de informatie op deze website
          aan te passen en kan dit doen zonder hiervan enige kennisgeving
          daarvan.
        </p>
        <p>
          Door vrijblijvend offertes aan te vragen ga je akkoord met onze
          algemene voorwaarden.
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, alphaNum, email, numeric } from "vuelidate/lib/validators";
import { helpers } from "vuelidate/lib/validators";
const alphaName = helpers.regex("alpha", /^[a-zA-Z\s.,'-]{1,30}$/);
const alphaPno = helpers.regex(
  "alpha",
  /^(0|\+31|0031|00 31)([-\s]?\d[-\s]?){9}$/
);
// const email = helpers.regex(
//   "alpha",
//   /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z]{2,}$/
// );

export default {
  name: "SecondForm",
  mixins: [validationMixin],
  data() {
    return {
      phoneRegex: /^(0|\+31|0031|00 31)([-\s]?\d[-\s]?){9}$/,
      nameRegex: /^[a-zA-Z\s.,'-]{1,30}$/,
      emailRegex: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z]{2,}$/,
      bootCheck: false,
      activeWeetIkNiet: "not_accepted",
      hoeveel: "",
      text_answers: [],
      weetIkNiet: "",
      second_form: {
        language: "nl_NL",
        site_custom_name: "offertevergelijker_airco",
        site_custom_url: "https://offertevergelijker.verbeterthuis.nl/airco",
        firstname: "",
        lastname: "",
        phone_number: "",
        email: "",
      },
    };
  },
  methods: {
    ...mapActions(["postLead"]),
    handleFieldInput(fieldName) {
      console.log("phone number changed", fieldName);
      if (this.getErrorResponses && !this.getErrorResponses[fieldName]) {
        console.log("phone number changed2", fieldName);
        this.$store.commit("setError", {
          ...this.getErrorResponses,
          [fieldName]: true,
        });
      }
    },
    onSubmit() {
      this.postLeadForm();
    },
    async postLeadForm() {
      if (this.activeWeetIkNiet === "not_accepted") {
        this.text_answers[1513] = this.weetIkNiet;
      } else {
        this.text_answers[1513] = "Ik kan geen schatting maken";
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        const phoneNumberWithoutPunctuation = this.second_form.phone_number
          .replace(/[\s-]/g, "")
          .replace(/^00(\d+)$/, "$1");
        let restRequiredParameters = {
          zip: this.first_form.zip,
          house_number: this.first_form.house_number,
          street: this.address.street,
          city: this.address.settlement,
          answers: [
            "3997",
            this.first_form.koop,
            this.hoeveel == "3" || this.hoeveel == "4" || this.hoeveel == "5"
              ? "4012"
              : this.hoeveel,
          ],
          site_subid: this.$route.query.click,
          publisher_id: this.$route.query.site,
        };
        if (this.first_form.koop !== "4000") {
          restRequiredParameters.answers.push(this.first_form.plaatsen);
        }
        let text_answers = this.text_answers;
        let resp = await this.postLead({
          ...this.second_form,
          bootCheck: this.bootCheck,
          ...restRequiredParameters,
          text_answers,
          phone_number: phoneNumberWithoutPunctuation,
        });
        if (resp.success || resp.statusCode === 409) {
          this.$router.push("bedankt");
        }
        if (
          this.getErrorResponses.zip == false &&
          this.getErrorResponses.housenumber == false
        ) {
          console.log("secondform redirection");

          await this.closeSecondForm();
          return;
        }
      }
    },

    // validations
    validateState(firstname) {
      const { $dirty, $error } = this.$v.second_form[firstname];
      return $dirty ? !$error : null;
    },
    validateState(lastname) {
      const { $dirty, $error } = this.$v.second_form[lastname];
      return $dirty ? !$error : null;
    },
    validateState(phone_number) {
      const { $dirty, $error } = this.$v.second_form[phone_number];
      return $dirty ? !$error : null;
    },
    validateState(email) {
      const { $dirty, $error } = this.$v.second_form[email];
      return $dirty ? !$error : null;
    },
  },
  validations: {
    second_form: {
      firstname: {
        required,
        alphaName,
      },
      lastname: {
        required,
        alphaName,
      },
      phone_number: {
        required,

        alphaPno,
      },
      email: {
        required,
        email,
      },
    },
  },

  computed: {
    ...mapGetters(["getLoader", "first_form", "address", "getErrorResponses"]),
    // phoneValidation() {
    //   return (
    //     this.phoneRegex.test(this.second_form.phone_number) ||
    //     this.second_form.phone_number === ""
    //   );
    // },
    // firstNameValidation() {
    //   return (
    //     this.nameRegex.test(this.second_form.firstname) ||
    //     this.second_form.firstname === ""
    //   );
    // },
    // lastNameValidation() {
    //   return (
    //     this.nameRegex.test(this.second_form.lastname) ||
    //     this.second_form.lastname === ""
    //   );
    // },
    // emailValidation() {
    //   return (
    //     this.emailRegex.test(this.second_form.email) ||
    //     this.second_form.email === ""
    //   );
    // },
    // isValidForm() {
    //   return (
    //     this.phoneValidation &&
    //     this.firstNameValidation &&
    //     this.lastNameValidation &&
    //     this.emailValidation
    //   );
    // },
  },
};
</script>

<style>
.hidden-checkbox {
  opacity: 0;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.text-secondary {
  color: #74a200 !important;
}
.font10 {
  font-size: 10px;
}
.bg-secondary {
  background: #74a200 !important;
}
.btnDiv {
  color: #69326d !important;
}

.text-purple {
  color: #69326d !important ;
}
label.btn input:checked + span {
  color: white !important;
}
</style>
