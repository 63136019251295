<template>
  <div class="banner">
    <SecondFormSection v-if="secondFormShow" />
    <!--  -->
    <b-container v-else class="py-3 py-md-5">
      <b-row>
        <div class="col-12 col-lg-6">
          <a href="#"><img src="../../assets/logo.png" /></a>
          <h3 class="banner__heading font-weight-bold pt-3">
            Hou je huis koel met airconditioning!
          </h3>
          <p class="banner-description">
            Vergelijk hier
            <span class="font-weight-bold"> gratis en vrijblijvend </span> tot 4
            offertes voor airconditioners en ontdek waar jij<span
              class="font-weight-bold"
            >
              de beste deal</span
            >
            krijgt.
          </p>

          <FirstForm v-if="first_form?.plaatsen === '4064'" />
          <div class="my-5" v-else>
            <p>
              Helaas is het voor huurders zonder toestemming niet mogelijk om
              een airco offerte aan te vragen bij ons
            </p>
            <span class="d-flex align-items-center">
              <h5 class="m-0 cursor-pointer" @click="showNextForm()">
                Ik heb wel toestemming van mijn verhuurder
              </h5>
              <img
                src="@/assets/btn-arrow.png"
                width="35"
                class="ml-3 cursor-pointer"
                @click="showNextForm()"
              />
            </span>
          </div>
        </div>
        <!--  -->
        <div class="col-12 col-lg-6 align-self-center mt-lg-0 mt-3 mt-md-5">
          <div class="bg-white shadow p-3 p-md-4 banner__ul my-5 my-md-0">
            <CheckList />
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FirstForm from "./FirstForm.vue";
import SecondFormSection from "./SecondFormSection.vue";
import CheckList from "../Airco/CheckList.vue";
export default {
  name: "Banner",
  components: { FirstForm, SecondFormSection, CheckList },
  computed: mapGetters(["secondFormShow", "first_form"]),
  methods: {
    ...mapActions(["showNextForm"]),
  },
};
</script>

<style lang="scss" scoped>
.font-weight-bold {
  font-weight: 700 !important;
}
.banner {
  &__heading {
    font-size: 26px !important;
  }
  &-description {
    color: #212529 !important;
    font-weight: 300;
    font-size: 17px;
    line-height: 24px;
  }
  &__ul {
    border-radius: 8px !important;
    border-left: 2px solid #eee !important;
    border-color: #74a200 !important;
  }
  &__icoColor {
    color: #74a200;
  }
  &__ul__styling {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  &__ul__text {
    // font-weight: 700;
    margin-top: 7px !important;
    font-size: 15px;
  }

  @media (max-width: 767.99px) {
    &__heading {
      font-size: 24px !important;
    }
  }
}
</style>
