<template>
  <b-container class="py-4">
    <b-row>
      <div class="header w-100">
        <a href="#"><img src="../../assets/isolatie-logo.png" /></a>
      </div>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style lang="scss" scoped>
.header {
  @media (max-width: 576px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
