<template>
  <div>
    <b-container class="py-md-5">
      <b-row cols=" py-0 py-md-4">
        <div class="col-12 col-md-6 col-lg-6">
          <a href="#"><img src="../assets/logo.png" /></a>
          <h3 class="font-weight-bold m-0">
            Goed nieuws! Jouw woning
            <span class="text-secondary">
              Lupineberg {{ first_form.house_number
              }}<!----></span
            >
            in <span class="text-secondary">Roosendaal </span> is geschikt voor
            zonnepanelen.
          </h3>
          <div class="my-2 my-md-4">
            <div>
              <GmapMap
                :options="{
                  zoomControl: false,
                  fullscreenControl: false,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: true,
                  disableDefaultUi: false,
                }"
                :center="{ lat: this.lat, lng: this.lng }"
                :zoom="16"
                map-type-id="terrain"
                style="width: 100%; height: 350px"
              >
                <GmapMarker
                  :position="{
                    lat: this.lat,
                    lng: this.lng,
                  }"
                  :clickable="true"
                  :draggable="true"
                />
              </GmapMap>
            </div>
          </div>
          <h3 class="text-center">Bespaar tot 30% in maar 2 minuten!</h3>
        </div>
        <!--  -->
        <div class="col-12 col-md-6">
          <div class="bg-white shadow p-3 p-md-4 banner__form">
            <SecondForm />
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SecondForm from "./SecondForm.vue";
export default {
  name: "SecondFormSection",
  components: { SecondForm },
  computed: mapGetters(["lng", "lat", "first_form"]),
};
</script>

<style lang="scss" scoped>
.text-secondary {
  color: #74a200 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.banner {
  &__form {
    border-radius: 8px !important;
    border-left: 2px solid #eee !important;
    border-color: #74a200 !important;
  }
}
</style>
