import { RepositoryFactory } from "../../repository/RepositoryFactory";

let leads = RepositoryFactory.get("leads");
const state = {
  getLoader: false,
  id: "",
  temp_form: { plaatsen: "4064" },
  secondFormShow: false,
  getErrorResponse: {}
};

const getters = {
  getLoader: (state) => state.getLoader,
  profId: (state) => state.id,
  first_form: (state) => state.temp_form,
  secondFormShow: (state) => state.secondFormShow,
  getErrorResponses: (state) => state.getErrorResponse,
};

const actions = {
  async postLead({ commit }, payload) {
    commit("setGetLoader", true);
    try {
      if (payload.bootCheck == true) {
        commit("setGetLoader", false);

        return {
          success: true,
        };
      } else {
        const response = await leads.postLead(payload);
        commit("setGetLoader", false);
        const responseId = response.data.profile.id;
        commit("id", responseId);
        return {
          success: true,
          profileid: responseId,
        };
      }

    } catch (error) {
      commit("setGetLoader", false);
      // const statusCode = error.response.data.code;
      // if (statusCode !== 409) {
      //   alert(error.response.data.error);
      // }
      // return {
      //   statusCode,
      //   success: false,
      // };
      if (error.response && error.response.data.error === "Conflict - duplicate") {
        console.log("error=>", error.response.data.error)

        commit("id", null);
        return {
          success: true,
          profileid: null,

        };
      } else {
        const errorKey = error.response.data.error.match(/'([^']+)'/)[0].slice(1, -1);
        commit("setError", { [errorKey]: false });
        console.error("Error Detail:", errorKey);
        return {
          success: false,
        };
      }
    }
  },
  async postNewLead({ commit }, payload) {
    console.log("payload-->", payload);
    commit("setGetLoader", true);
    try {
      if (payload.bootCheck == true) {
        commit("setGetLoader", false);

        return {
          success: true,
        };
      } else {
        const response = await leads.postNewLead(payload);
        commit("setGetLoader", false);
        const responseId = response.data.profile.id;
        commit("id", responseId);
        return {
          success: true,
          profileid: responseId,
        };
      }

    } catch (error) {
      commit("setGetLoader", false);
      // const statusCode = error.response.data.code;
      // if (statusCode !== 409) {
      //   alert(error.response.data.error);
      // }
      // return {
      //   statusCode,
      //   success: false,
      // };
      if (error.response && error.response.data.error === "Conflict - duplicate") {
        console.log("error=>", error.response.data.error)

        commit("id", null);
        return {
          success: true,
          profileid: null,

        };
      } else {
        const errorKey = error.response.data.error.match(/'([^']+)'/)[0].slice(1, -1);
        commit("setError", { [errorKey]: false });
        console.error("Error Detail:", errorKey);
        return {
          success: false,
        };
      }
    }
  },

  async postZonnepanelenLead({ commit }, payload) {

    try {
      if (payload.bootCheck == true) {
        return {
          success: true,
        };

      } else {
        const response = await leads.postZonnepanelenLead(payload);
        const responseId = response.data.profile.id;
        commit("id", responseId);
        return {
          success: true,
          profileid: responseId,
        };
      }

    } catch (error) {
      // const statusCode = error.response.data.code;

      // if (error?.response?.data?.error == "Conflict - duplicate") {
      //   return {
      //     statusCode,
      //     success: true,
      //   };
      // } else {
      //   alert(error.response.data.error);
      //   return {
      //     statusCode,
      //     success: false,
      //   };
      if (error.response && error.response.data.error === "Conflict - duplicate") {
        console.log("error=>", error.response.data.error)

        commit("id", null);
        return {
          success: true,
          profileid: null,

        };
      } else {
        const errorKey = error.response.data.error.match(/'([^']+)'/)[0].slice(1, -1);
        commit("setError", { [errorKey]: false });
        console.error("Error Detail:", errorKey);
        return {
          success: false,
        };

      }
    }
  },

  async setFirstFormData({ commit }, payload) {
    commit("setFirstFormData", payload);
    if (payload.plaatsen == "4064") {
      commit("setShowNextForm");
    }
  },
  async showNextForm({ rootState, commit }) {
    let data = { ...rootState.Leads.temp_form, plaatsen: "4064" };
    commit("setFirstFormData", data);
    commit("setShowNextForm");
  },
  async closeSecondForm({ commit }) {
    state.secondFormShow = false;
  },
};

const mutations = {
  setGetLoader(state, payload) {
    state.getLoader = payload;
  },
  setFirstFormData(state, payload) {
    state.temp_form = payload;
  },
  setShowNextForm(state) {
    state.secondFormShow = true;
  },
  id(state, payload) {
    state.id = payload;
  },
  setError(state, payload) {
    state.getErrorResponse = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
