<template>
  <div class="home">
    <Banner />
    <WaaromZonnepanelen />
    <Footer />
  </div>
</template>

<script>
import Banner from "../components/Banner.vue";
import WaaromZonnepanelen from "../components/WaaromZonnepanelen.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "Home",
  components: {
    Banner,
    WaaromZonnepanelen,
    Footer,
  },
};
</script>
<style >
.home {
  background: url(../assets/background.jpg) no-repeat 50%;
  background-size: cover;
}
</style>
