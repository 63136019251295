<template>
  <div class="isolatie-wrapper">
    <Header />
    <HeroSection :showSecondForm="showSecondForm" @changeState="updateState" />

    <GuideSection :showSecondForm="showSecondForm" />
    <InfoSection :showSecondForm="showSecondForm" />
    <WhyInsultHome :showSecondForm="showSecondForm" />

    <Footer />
  </div>
</template>

<script>
import Header from "../components/Isolatiev2/Header.vue";
import HeroSection from "../components/Isolatiev2/HeroSection.vue";
import GuideSection from "../components/Isolatiev2/GuideSection.vue";
import InfoSection from "../components/Isolatiev2/InfoSection.vue";
import WhyInsultHome from "../components/Isolatiev2/WhyInsultHome.vue";
import Footer from "../components/Isolatiev2/Footer.vue";
export default {
  name: "Isolatie",
  data() {
    return {
      showSecondForm: false,
    };
  },
  watch: {
    showSecondForm: {
      handler: function (newVal, oldVal) {
        window.scrollTo(0, 0);
      },
      deep: true,
    },
  },
  components: {
    Header,
    HeroSection,
    GuideSection,
    InfoSection,
    WhyInsultHome,
    Footer,
  },
  methods: {
    updateState(value) {
      console.log("statevalue", value);
      this.showSecondForm = value;
    },
  },
  mounted() {
    let useScript = document.createElement("script");

    useScript.setAttribute(
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };

        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";

        n.queue = [];
        t = b.createElement(e);
        t.async = !0;

        t.src = v;
        s = b.getElementsByTagName(e)[0];

        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",

        "https://connect.facebook.net/en_US/fbevents.js"
      ),

      fbq("init", "969816563708997"),

      fbq("track", "PageView")
    ),
      document.head.appendChild(useScript);
  },

  created() {
    document.title = "Offertevergelijker";
    this.noscriptHtml =
      '<noscript><img height="1" width="1" style="display:none"src="https://www.facebook.com/tr?id=969816563708997&ev=PageView&noscript=1"/></noscript>';
  },
  // watch: {
  //   showSecondForm: {
  //     handler: function (newVal, oldVal) {
  //       console.log("change");
  //     },
  //     deep: true,
  //   },
  // },
};
</script>
<style>
.isolatie-wrapper {
  font-family: "Montserrat";
}
</style>
