var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-container',{staticClass:"py-md-4 py-3"},[_c('b-row',{attrs:{"cols":" py-0 py-md-4"}},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("../../assets/logo.png")}})]),_c('h3',{staticClass:"banner__heading font-weight-bold m-0"},[_vm._v(" Goed nieuws! Jouw woning "),_c('span',{staticClass:"text-secondary"},[_vm._v(" "+_vm._s(this.address?.street)+" "+_vm._s(this.address?.streetNumber)+_vm._s(this.address?.premise))]),_vm._v(" in "),_c('span',{staticClass:"text-secondary"},[_vm._v(_vm._s(this.address?.settlement)+" ")]),_vm._v(" komt in aanmerking voor gratis airco offertes. ")]),_c('div',{staticClass:"my-2 my-md-4"},[_c('div',[_c('GmapMap',{staticStyle:{"width":"100%","height":"250px"},attrs:{"options":{
                zoomControl: false,
                fullscreenControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: true,
                disableDefaultUi: false,
              },"center":{ lat: this.lat, lng: this.lng },"zoom":16,"map-type-id":"terrain"}},[_c('GmapMarker',{attrs:{"position":{
                  lat: this.lat,
                  lng: this.lng,
                },"clickable":true,"draggable":true}})],1)],1)]),_c('CheckList')],1),_c('div',{staticClass:"col-12 col-lg-6 mt-lg-0 mt-4"},[_c('div',{staticClass:"bg-white shadow p-3 px-md-4 py-md-5 banner__form"},[_c('SecondForm')],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }