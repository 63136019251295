<template>
  <div>
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <div class="col-12 col-md-6">
          <b-form-group id="Postcode" label="Postcode" label-for="Postcode">
            <b-form-input id="Postcode" v-model="temp_form.zip" placeholder="bv. 2000AB" type="text" maxLength="6"
              :state="zipValidation" aria-describedby="input-1-live-feedback" required></b-form-input>
            <b-form-invalid-feedback id="input-1-live-feedback">Must be at least 4 numeric and 2 alphabet
              characters.</b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-12 col-md-6">
          <label for="house">Huisnummer</label>
          <b-form-input v-model="$v.temp_form.house_number.$model" type="text" id="house"
            :state="validateState('house_number')" placeholder="bv. 12"></b-form-input>
        </div>
      </b-row>
      <b-row>
        <div class="col-12">
          <label for="rentalOrOwner">Heb je een huur- of koopwoning?</label>
          <div class="btnDiv mb-2">
            <label class="btn btn-lg col-6 p-0 m-0"><input value="3277" v-model="temp_form.koop" type="radio"
                name="toggle" /><span>Koopwoning</span></label>
            <label class="btn btn-lg col-6 p-0 m-0"><input value="3280" v-model="temp_form.koop" type="radio"
                name="toggle" /><span>Huurwoning</span></label>
          </div>
        </div>
      </b-row>
      <b-row>
        <div class="col-12">
          <label for="roof">Wat voor soort dak heb je?</label>
          <div>
            <label class="btn btn-lg col-6 p-0 m-0"><input class="btnDiv" value="3283" v-model="temp_form.dak"
                type="radio" name="roof" required /><span class="btnDiv mr-0 mr-md-2"><img src="../assets/svg1.svg"
                  class="" height="100%" width="50%" />
                <br />
                Schuin dak
              </span></label>
            <label class="btn btn-lg col-6 p-0 m-0"><input value="3286" v-model="temp_form.dak" type="radio" name="roof"
                required /><span class="btnDiv ml-0 ml-md-2"><img src="../assets/svg2.svg" class="mb-2" height="100%"
                  width="50%" /><br />
                Plat dak
              </span></label>
          </div>
        </div>
      </b-row>
      <b-row>
        <b-col lg="12" class="mt-3"><b-button size="lg" type="submit" variant="success" class="w-100 py-3"
            style="background: #759e62; border: #74a200">
            <span><b>
                Bekijk of mijn woning <br />
                in aanmerking komt
              </b></span></b-button></b-col></b-row>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength, alphaNum } from "vuelidate/lib/validators";
export default {
  name: "FirstForm",
  mixins: [validationMixin],
  data() {
    return {
      validationCheck: true,
      temp_form: {
        zip: "",
        house_number: "",
        koop: "3277",
        dak: "",
      },
    };
  },
  methods: {
    ...mapActions(["temp_formData", "getLocation"]),

    onSubmit() {
      if (this.validationCheck) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.submitStatus = "ERROR";
        } else {
          this.getLocation({ ...this.temp_form });
          this.temp_formData(this.temp_form);
        }
      }
    },
    validateState(zip) {
      const { $dirty, $error } = this.$v.temp_form[zip];
      return $dirty ? !$error : null;
    },
    validateState(house_number) {
      const { $dirty, $error } = this.$v.temp_form[house_number];
      return $dirty ? !$error : null;
    },
  },
  validations: {
    temp_form: {
      house_number: {
        required,
        alphaNum,
      },
    },
  },
  computed: {
    zipValidation() {
      if (this.temp_form.zip == "") {
        this.validationCheck = false;
        return null;
      }
      let zipRegex = /^([0-9]{4}[a-zA-Z]{2})$/;
      if (this.temp_form.zip.match(zipRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
  },
};
</script>

<style>
.btnDiv {
  border: 2px solid #69326d;
  border-radius: 6px;
}

label.btn {
  padding: 0;
}

label.btn input {
  opacity: 0;
  position: absolute;
}

label.btn span {
  text-align: center;
  border-radius: 3px;
  padding: 12px 12px;
  display: block;
  margin: -2px;
}

label.btn input:checked+span {
  background-color: #69326d;
  color: #fff;
  font-weight: 400;
}
</style>
