<template>
  <div class="airco">
    <!-- <div class="airco-banner"></div> -->
    <Banner />
    <WaaromZonnepanelen />
    <AirConditioning />
    <Footer />
  </div>
</template>

<script>
import Banner from "../components/Airco/Banner.vue";
import WaaromZonnepanelen from "../components/Airco/WaaromZonnepanelen.vue";
import Footer from "../components/Airco/Footer.vue";

import AirConditioning from "../components/Airco/AirConditioning.vue";

export default {
  name: "Airco",
  components: {
    Banner,
    WaaromZonnepanelen,
    Footer,
    AirConditioning,
  },

  mounted() {
    let useScript = document.createElement("script");
    useScript.setAttribute(
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        }
        if (!f._fbq) f._fbq = n,
        n.push = n,
        n.loaded = !0,
        n.version = "2.0",
        n.queue = [],
        t = b.createElement(e),
        t.async = !0,
        t.src = v,
        s = b.getElementsByTagName(e)[0],
        s.parentNode.insertBefore(t, s)
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      ),
      fbq("init", "969816563708997"),
      fbq("track", "PageView"),

    ),
      document.head.appendChild(useScript);
  },
};
</script>
<style>
.airco {
  /* background: url(../assets/airco-bg.jpg) no-repeat 50%;
  background-size: cover; */
  background: url(../assets/airco-bg.jpg) no-repeat;
  background-size: 62%;
  background-position: 100% 0;

  @media (max-width: 767.99px) {
    background: none !important;
  }
}
</style>
