<template>
  <div
    class="infoSection"
    :class="{ 'd-none': showSecondForm, 'd-md-block': showSecondForm }"
  ></div>
</template>

<script>
export default {
  name: "InfoSection",
  props: {
    showSecondForm: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.infoSection {
  background: url(../../assets/info-bg.png) no-repeat;
  background-size: cover;
  height: 350px;
  @media (max-width: 567.98px) {
    background: url(../../assets/info-bg-sm.png) no-repeat;
    background-size: 100%;
  }
}
</style>
