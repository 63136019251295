<template>
  <div>
    <h4 class="font-weight-bold">
      Om je de gratis berekening te kunnen sturen hebben we alleen nog
      onderstaande gegeven
    </h4>
    <div class="my-4">
      <b-form @submit.prevent="onSubmit">
        <b-form-group
          id="kWh"
          label="Hoeveel kWh verbruik je per jaar?"
          label-for="kWh"
        >
          <b-form-select
            id="kWh"
            v-model="$v.second_form.kWh.$model"
            :state="validateState('kWh')"
            :options="kWh"
            required
          ></b-form-select>
        </b-form-group>
        <!--  -->
        <b-form-group
          id="when"
          label="Wanneer wil je de zonnepanelen laten plaatsen?"
          label-for="when"
        >
          <b-form-select
            id="when"
            v-model="$v.second_form.when.$model"
            :state="validateState('when')"
            :options="when"
            required
          ></b-form-select>
        </b-form-group>
        <!--  -->
        <b-row>
          <div class="col-12 col-md-6">
            <b-form-group id="fName" label="Voornaam" label-for="fName">
              <b-form-input
                id="fName"
                v-model="$v.second_form.firstname.$model"
                :state="validateState('firstname')"
                placeholder="bv. Pieter"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-12 col-md-6">
            <b-form-group id="lName" label="Achternaam" label-for="lName">
              <b-form-input
                id="lName"
                v-model="$v.second_form.lastname.$model"
                :state="validateState('lastname')"
                placeholder="bv. de Jong"
                required
              ></b-form-input>
            </b-form-group>
          </div>
        </b-row>
        <b-row>
          <div class="col-12 col-md-6">
            <b-form-group id="tel" label="Telefoonnummer" label-for="tel">
              <b-form-input
                id="tel"
                type="tel"
                maxlength="10"
                v-model="$v.second_form.phone_number.$model"
                :state="validateState('phone_number')"
                placeholder="bv. 0612345678"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-12 col-md-6">
            <label for="email">E-mail</label>
            <b-form-input
              v-model="second_form.email"
              :state="emailValidation"
              type="email"
              id="email"
              placeholder="bv. piet@dejong.nl"
            ></b-form-input>
          </div>
        </b-row>
        <b-col lg="12" class="py-4"
          ><b-button
            size="lg"
            type="submit"
            variant="success"
            class="w-100 py-3"
            style="background: #759e62; border: #74a200"
          >
            <b-spinner v-if="getLoader" /><span v-else
              ><b> Bevestigen ></b></span
            ></b-button
          ></b-col
        >
      </b-form>
      <div class="text-center">
        <small class="text-muted font10">
          Door vrijblijvend een gratis berekening aan te vragen ga je akkoord
          met onze
          <span class="cursor-pointer" v-b-modal.Algemene>
            Algemene voorwaarden</span
          ></small
        >
      </div>
    </div>
    <!-- modal -->
    <b-modal
      id="Algemene"
      centered
      title="Algemene voorwaarden"
      ok-only
      size="lg"
      ok-variant="success"
      scrollable
    >
      <div>
        <p>
          Via Offertevergelijker.nl vragen dagelijks vele Nederlanders offertes
          aan voor zijn of haar klus. Nadat je een offerteaanvraag hebt
          doorgestuurd via één van onze formulieren, bekijken wij de aanvraag en
          indien deze akkoord is, zoeken wij maximaal drie bedrijven waarvan wij
          denken dat ze u een goede aanbieding kunnen doen. Het aanvragen van
          offertes is gratis en vrijblijvend.
        </p>
        <h4>Persoonsgegevens</h4>
        <p>
          Om een offerteaanvraag door te kunnen zetten naar relevante partners,
          hebben wij enkele persoonsgegevens van u nodig. Onder persoonsgegevens
          verstaan wij ieder gegeven dat je aan ons doorgeeft, waarmee jij als
          persoon aanwijsbaar bent. Voorbeelden van persoonsgegevens zijn je
          naam en adres.
        </p>
        <p>
          Wij vragen tevens om een telefoonnummer. Deze vragen wij, omdat de
          geselecteerde bedrijven bij jouw aanvraag in sommige gevallen
          aanvullende vragen hebben. Zo kunnen zij de offerte zo op maat
          mogelijk maken en krijgt u het beste overzicht van de mogelijkheden.
        </p>
        <h4>Geen garantie</h4>
        <p>
          Bij Offertevergelijker brengen wij particulieren en bedrijven bij
          elkaar. De rol van Offertevergelijker.nl gaat nooit verder dan het
          faciliteren van vraag en aanbod. Wij geven geen garantie op de
          geleverde diensten of uitgevoerde werkzaamheden. Offertevergelijker.nl
          is dan ook niet aansprakelijk voor eventuele schade door of
          onwenselijk gedrag van het uitvoerende bedrijf. U blijft te allen
          tijde zelf verantwoordelijk voor het vastleggen van goede afspraken
          met het bedrijf dat uw werkzaamheden uitvoert.
        </p>
        <h4>Wijzigingen algemene voorwaarden</h4>
        <p>
          Offertevergelijker heeft het recht om de informatie op deze website
          aan te passen en kan dit doen zonder hiervan enige kennisgeving
          daarvan.
        </p>
        <p>
          Door onze website te bezoeken, ga je automatisch akkoord met deze
          algemene voorwaarden.
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, alphaNum, email, numeric } from "vuelidate/lib/validators";

export default {
  name: "SecondForm",
  mixins: [validationMixin],
  data() {
    return {
      emailErr: true,
      second_form: {
        kWh: "null",
        when: "null",
        firstname: "",
        lastname: "",
        phone_number: "",
        email: "",
      },
      kWh: [
        { text: "Maak een keuze", value: null, disabled: true },
        { text: "Ik kan geen schatting maken", value: "3289", disabled: false },
        { text: "2.300 kWh (1 persoon)", value: "3292", disabled: false },
        { text: "3.400 kWh (2 personen)", value: "3295", disabled: false },
        { text: "4.100 kWh (3 personen)", value: "3298", disabled: false },
        { text: "4.600 kWh (4 personen)", value: "3301", disabled: false },
        { text: "5.300 kWh (5 personen)", value: "3304", disabled: false },
        { text: "5.400 kWh (6 personen)", value: "3307", disabled: false },
        { text: "Meer dan 5.400 kWh", value: "3310", disabled: false },
      ],
      when: [
        { text: "Selecteer wanneer", value: null, disabled: true },
        { text: "In overleg", value: "3313", disabled: false },
        { text: "Binnen 3 maanden", value: "3316", disabled: false },
        { text: "Binnen 3 tot 6 maanden", value: "3319", disabled: false },
      ],
    };
  },
  methods: {
    ...mapActions(["postLead"]),
    onSubmit() {
      if (!this.emailErr) {
        this.confirmEmail();
      } else {
        this.postLeadForm();
      }
    },
    confirmEmail() {
      this.$bvModal
        .msgBoxOk(
          "Je e-mail adres lijkt een spelfout te bevatten. Gelieve deze te controleren voordat u verder gaat.",
          {
            size: "md",
            buttonSize: "sm",
            okVariant: "success",
            okTitle: "Oké",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          this.emailErr = value;
        });
    },
    async postLeadForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        let form = {
          language: "nl_NL",
          firstname: this.second_form.firstname,
          lastname: this.second_form.lastname,
          email: this.second_form.email,
          phone_number: this.second_form.phone_number,
          zip: this.first_form.zip,
          house_number: this.first_form.house_number,
          answers: [
            "3274",
            this.first_form.koop,
            this.first_form.dak,
            this.second_form.kWh,
            this.second_form.when,
          ],
        };
        let resp = await this.postLead({
          ...form,
          site_subid: this.$route.query.oa_id,
          publisher_id: this.$route.query.site,
        });
        if (resp.success == true) {
          this.$router.push("bedankt");
        }
      }
    },

    // validations
    validateState(kWh) {
      const { $dirty, $error } = this.$v.second_form[kWh];
      return $dirty ? !$error : null;
    },
    validateState(when) {
      const { $dirty, $error } = this.$v.second_form[when];
      return $dirty ? !$error : null;
    },
    validateState(firstname) {
      const { $dirty, $error } = this.$v.second_form[firstname];
      return $dirty ? !$error : null;
    },
    validateState(lastname) {
      const { $dirty, $error } = this.$v.second_form[lastname];
      return $dirty ? !$error : null;
    },
    validateState(phone_number) {
      const { $dirty, $error } = this.$v.second_form[phone_number];
      return $dirty ? !$error : null;
    },
    validateState(email) {
      const { $dirty, $error } = this.$v.second_form[email];
      return $dirty ? !$error : null;
    },
  },
  validations: {
    second_form: {
      kWh: {
        required,
      },
      when: {
        required,
      },
      firstname: {
        required,
        alphaNum,
      },
      lastname: {
        required,
        alphaNum,
      },
      phone_number: {
        required,
        numeric,
      },
    },
  },

  computed: {
    ...mapGetters(["getLoader", "first_form"]),

    emailValidation() {
      const email = this.second_form.email.split("@");
      let check = email[1];
      if (this.second_form.email == "") {
        return null;
      }

      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          this.second_form.email
        )
      ) {
        if (
          check == `gmail.com` ||
          check == `outlook.com` ||
          check == `yahoo.com` ||
          check == `icloud.com`
        ) {
          this.emailErr = true;
        } else {
          this.emailErr = false;
        }
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.font-weight-bold {
  font-weight: 700 !important;
}
.text-secondary {
  color: #74a200 !important;
}
.font10 {
  font-size: 10px;
}
.bg-secondary {
  background: #74a200 !important;
}
</style>
