<template>
  <div>
    <div><img src="../../assets/airco.jpg" class="w-100" /></div>
    <b-container>
      <b-row class="py-3 py-md-4 conditioning">
        <div class="col-12 col-md-6">
          <p class="conditioning-purple mt-2 mb-0">
            Airco offertes vergelijken
          </p>
          <p class="conditioning-description mt-1 mb-0">
            Veel woningeigenaren kiezen er tegenwoordig voor om een vaste
            airconditioner te laten installeren in huis. Wil jij ook meer weten
            over de mogelijkheden? Ontdek hieronder alles over de voordelen,
            verschillende soorten en kosten van een airco.
          </p>

          <p class="conditioning-green mt-2 mb-0">Voordelen airconditioning</p>
          <p class="conditioning-description mt-1 mb-0">
            Dat steeds meer mensen een airco laten installeren, is niet vreemd
            als je kijkt hoe groot de voordelen zijn. Hieronder hebben we de
            vier grootste voor je op een rijtje gezet.
          </p>

          <p class="conditioning-dark mt-2 mb-0">
            Voordeel 1: een heerlijk koel huis in de zomer
          </p>
          <p class="conditioning-description mt-1 mb-0">
            Vanzelfsprekend is een goede airconditioning op zomerse dagen een
            waar genot. In een koele woning leef en slaap je gewoon prettiger.
            Maar ook wanneer de zon minder hard schijnt, stijgt het kwik in veel
            huizen al snel boven de 25 graden. Niet bepaald aangenaam. Met een
            airco zorg je ervoor dat er binnenshuis altijd een constante
            temperatuur is die jij het prettigst vindt.
          </p>

          <p class="conditioning-dark mt-2 mb-0">
            Voordeel 2: verwarmen in de winter
          </p>
          <p class="conditioning-description mt-1 mb-0">
            Wist je dat je met veel airconditionings niet alleen kan koeler,
            maar dat deze ook uitermate geschikt zijn om ruimtes te verwarmen?
            Zo geniet je het hele jaar door van een aangenaam woonklimaat.
          </p>

          <p class="conditioning-dark mt-2 mb-0">
            Voordeel 3: constante toevoer frisse lucht
          </p>
          <p class="conditioning-description mt-1 mb-0">
            Naast de perfecte temperatuur zorgt een airco ook voor een gezonde
            luchtvochtigheid in huis. Doordat er een constante toestroom van
            nieuwe buitenlucht de woning binnenkomt, is er altijd voldoende
            frisse lucht in huis, zonder dat dit invloed heeft op de
            temperatuur. Deze ventilatie is bevorderlijk voor de
            luchtvochtigheid en hiermee ook goed voor de gezondheid. Het is wel
            van belang dat de airco goed is geïnstalleerd/ingesteld. Laat je
            daarom altijd vooraf goed inlichten.
          </p>

          <p class="conditioning-dark mt-2 mb-0">
            Voordeel 4: de airco is tegenwoordig heel duurzaam
          </p>
          <p class="conditioning-description mt-1 mb-0">
            Vroeger waren airco’s echte energieslurpers en zag je het gebruik
            direct terug op de energierekening. Inmiddels zijn de technieken van
            vaste airco’s vele malen beter en valt het verbruik reuze mee. Zeker
            in combinatie met zonnepanelen is een airconditioning ontzettend
            duurzaam. De levensduur van een vaste airconditioning is daarnaast
            ook erg verbeterd. Bij goed onderhoud gaat deze gemiddeld zo’n 12
            tot 15 jaar mee.
          </p>
        </div>

        <div class="col-12 col-md-6">
          <p class="conditioning-green mt-2 mb-0">
            Soorten en kosten airconditioning
          </p>
          <p class="conditioning-description mt-1 mb-0">
            Afhankelijk van jouw situatie kun je kiezen voor verschillende type
            airco’s. Ieder met hun eigen kostenplaatje.
          </p>

          <p class="conditioning-dark mt-2 mb-0">Split airconditioning</p>
          <p class="conditioning-description mt-1 mb-0">
            Het bekendste (en populairste) model is de split airconditioning.
            Dit type bestaat uit twee units; de buiten- en binnenunit. Deze
            airconditioning wordt op een vaste plaats in huis gemonteerd, koelt
            zeer efficiënt en maakt nauwelijks geluid. De kosten hangen erg af
            van de kwaliteit die je zoekt en de specialist die het plaatst, maar
            liggen tussen de € 1600 en € 3200 inclusief plaatsing. Voor iedere
            binnenunit die je extra wilt koppelen aan de buiten unit komt er
            gemiddeld € 900 bij.
          </p>

          <p class="conditioning-dark mt-2 mb-0">Monoblock airconditioning</p>
          <p class="conditioning-description mt-1 mb-0">
            Dit type airco bestaat uit slechts één unit die je aan de
            binnenzijde van je huis bevestigt. Middels een afvoerslang voert
            deze airco de warme lucht uit huis, terwijl de unit de kamer met
            koude lucht verkoelt. Het nadeel van dit type airco is dat de unit
            vrij veel lawaai maakt en relatief groot is vergeleken met de split
            airco. De kosten van deze airco, inclusief plaatsing, beginnen bij
            de € 1400.
          </p>

          <p class="conditioning-dark mt-2 mb-0">Mobiele airconditioning</p>
          <p class="conditioning-description mt-1 mb-0">
            Ga je de airco maar zelden gebruiken en waarschijnlijk vaak in een
            andere ruimte? Dan is een mobiel model interessant. Deze kun je
            aanschaffen bij de bouwmarkt of online voor prijzen tussen de € 300
            en € 600. De nadelen van dit model zijn dat hij veel stroom
            verbruikt, minder koelvermogen heeft dan een vast model en veel
            ruimte in beslag neemt.
          </p>

          <p class="conditioning-green mt-2 mb-0">
            Vergelijk gratis meerdere offertes
          </p>
          <p class="conditioning-description mt-1 mb-0">
            Overweeg je ook om een vaste airco te laten installeren? Vergelijk
            dan altijd eerst meerdere specialisten op het gebied van ervaring,
            prijs en garantie. Vraag nu in één minuut tot 4 gratis offertes aan
            en ontdek vrijblijvend waar jij de beste deal krijgt.
          </p>

          <p class="font-weight-bold mb-0 mt-md-3 mt-2 conditioning-text">
            Direct kijken of jouw huis in aanmerking komt? Doe de check en kom
            er direct achter of het geschikt is.
          </p>

          <b-button
            size="lg"
            type="submit"
            variant="success"
            class="conditioning-btn mt-3 mb-2"
            @click="scrollToTop"
          >
            <h5 class="m-0 px-2 px-md-3 py-2 text-white">
              Controleer je postcode »
            </h5></b-button
          >
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "AitConditioning",
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Use 'auto' for instant scrolling
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.conditioning {
  &-purple {
    color: #69326d;
    font-size: 32px;
    font-weight: bold;
  }
  &-green {
    color: #75a116;
    font-size: 28px;
    font-weight: bold;
  }
  &-description {
    color: #212529;
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
  }
  &-dark {
    color: #333333;
    font-size: 19px;
    font-weight: 500;
  }

  &-btn {
    background: #759e62;
    border: #74a200;
    color: white !important;
    font-size: 22px !important;
    &:focus,
    &:hover,
    &:active {
      background: #759e62 !important;
      border: #74a200 !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }

  @media (max-width: 991.99px) {
    &-purple {
      font-size: 25px;
    }
    &-green {
      font-size: 20px;
    }
    &-description {
      font-size: 12px;

      line-height: 20px;
    }
    &-dark {
      color: #333333;
      font-size: 15px;
      font-weight: 500;
    }
    &-text {
      font-size: 12px;
    }

    &-btn {
      font-size: 28px !important;
    }
  }
}
</style>