<template>
  <div class="zonnelpanelen-wrapper">
    <Header />
    <HeroSection :showSecondForm="showSecondForm" @changeState="updateState" />

    <GuideSection :showSecondForm="showSecondForm" />
    <InfoSection :showSecondForm="showSecondForm" />
    <WhyInsultHome :showSecondForm="showSecondForm" />

    <Footer />
  </div>
</template>

<script>
import Header from "../components/Zonnepanelenv2/Header.vue";
import HeroSection from "../components/Zonnepanelenv2/HeroSection.vue";
import GuideSection from "../components/Zonnepanelenv2/GuideSection.vue";
import InfoSection from "../components/Zonnepanelenv2/InfoSection.vue";
import WhyInsultHome from "../components/Zonnepanelenv2/WhyInsultHome.vue";
import Footer from "../components/Zonnepanelenv2/Footer.vue";
export default {
  name: "Zonnepanelen",
  data() {
    return {
      showSecondForm: false,
    };
  },
  watch: {
    showSecondForm: {
      handler: function (newVal, oldVal) {
        window.scrollTo(0, 0);
      },
      deep: true,
    },
  },
  components: {
    Header,
    HeroSection,
    GuideSection,
    InfoSection,
    WhyInsultHome,
    Footer,
  },
  methods: {
    updateState(value) {
      console.log("statevalue", value);
      this.showSecondForm = value;
    },
  },
};
</script>
<style>
.zonnelpanelen-wrapper {
  font-family: "Montserrat";
}
</style>
