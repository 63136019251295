<template>
  <b-container class="main-cont mb-3">
    <b-form class="mt-3" @submit.prevent="goToNextStep">
      <div>
        <h2 class="title">Samenstelling huishouden i.v.m. verbruik</h2>
        <b-form-group class="mt-4">
          <b-form-radio
            required
            v-model="forthQuestion.houseBuilt"
            name="ownership"
            value="4599"
          >
            1 persoon
          </b-form-radio>
          <b-form-radio
            required
            v-model="forthQuestion.houseBuilt"
            name="ownership"
            value="4602"
            class="my-3"
          >
            2 personen
          </b-form-radio>
          <b-form-radio
            required
            v-model="forthQuestion.houseBuilt"
            name="ownership"
            value="4605"
            class="my-3"
          >
            3 personen
          </b-form-radio>
          <b-form-radio
            required
            v-model="forthQuestion.houseBuilt"
            name="ownership"
            value="4608"
            class="my-3"
          >
            4 personen
          </b-form-radio>

          <b-form-radio
            required
            v-model="forthQuestion.houseBuilt"
            name="ownership"
            value="4611"
            class="my-3"
          >
            5 personen
          </b-form-radio>
          <b-form-radio
            required
            v-model="forthQuestion.houseBuilt"
            name="ownership"
            value="4647"
            class="my-3"
          >
            6 personen
          </b-form-radio>

          <b-form-radio
            required
            v-model="forthQuestion.houseBuilt"
            name="ownership"
            value="4650"
            class="my-3"
          >
            Meer dan 6 personen
          </b-form-radio>
        </b-form-group>
      </div>
      <div>
        <button
          type="submit"
          class="rounded-pill btnForm px-2 sm-bg-btn px-md-5 py-2 mt-2"
        >
          Volgende
          <!-- {{
            thirdQuestion.selectedOption.includes("Dak")
              ? " Volgende"
              : "Ga naar het resultaat"
          }} -->
        </button>
      </div>
    </b-form>
  </b-container>
</template>

<script>
export default {
  name: "ForthQuestion",
  props: {
    step: {
      type: Number,
    },
    forthQuestion: {
      type: Object,
    },
    thirdQuestion: {
      type: Object,
    },
    fifthQuestion: {
      type: Object,
    },
  },
  methods: {
    nextQuestion() {
      this.thirdQuestion.activeStep = 2;
      this.thirdQuestion.loader = true;
      setTimeout(() => {
        if (this.thirdQuestion.activeStep === 2) {
          this.thirdQuestion.activeStep = 3;
          this.thirdQuestion.loader = false;
        }
      }, 2000);
    },

    // id="spouwmuur"
    // value="4418"

    // id="dak"
    //         value="4409"
    //         id="vloer"
    //         value="4415"
    //         id="kozijnen"
    //         value="4412"

    goToNextStep() {
      console.log(this.forthQuestion.houseBuilt, "Forth question");
      this.$emit("gotoStepFifth", this.step + 2);

      // if (
      //   this.thirdQuestion.selectedOption.includes("4579") ||
      //   this.thirdQuestion.selectedOption.includes("4576")
      // ) {
      //   if (this.thirdQuestion.selectedOption.includes("4563")) {
      //     this.$emit("gotoStepThree", this.step - 1);
      //     this.thirdQuestion.activeStep = 3;
      //   } else if (this.thirdQuestion.selectedOption.includes("4566")) {
      //     this.fifthQuestion.activeStep = false;
      //     this.$emit("gotoStepFifth", this.step + 1);
      //   } else {
      //     this.$emit("gotoStepFifth", this.step + 2);
      //   }
      // } else if (this.thirdQuestion.selectedOption.includes("4563")) {
      //   this.$emit("gotoStepThree", this.step - 1);
      //   this.thirdQuestion.activeStep = 3;
      // } else if (this.thirdQuestion.selectedOption.includes("4566")) {
      //   this.fifthQuestion.activeStep = false;
      //   this.$emit("gotoStepFifth", this.step + 1);
      // }
    },

    // goToNextStep() {
    //     if (this.thirdQuestion.selectedOption.includes('4409')) {
    //         this.$emit('gotoStepFifth', this.step + 1);
    //     } else {
    //         this.$emit('gotoStepFifth', this.step + 2);
    //     }
    // }
  },
};
</script>

<style lang="scss" scoped></style>
