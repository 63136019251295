<template>
  <div>
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <div class="col-12 col-md-6">
          <b-form-group id="Postcode" label="Postcode" label-for="Postcode">
            <b-form-input
              id="Postcode"
              v-model="temp_form.zip"
              placeholder="2000 AB"
              type="text"
              @input="handleFieldInput('zip')"
              :class="this.getErrorResponses.zip == false ? 'is-invalid' : ''"
              :state="zipValidation"
              aria-describedby="input-1-live-feedback"
              required
            ></b-form-input>
            <b-form-invalid-feedback id="input-1-live-feedback"
              >Must be at least 4 numeric and 2 alphabet
              characters.</b-form-invalid-feedback
            >
            <b-form-invalid-feedback v-if="!zipValidation">
              Postcode niet correct
            </b-form-invalid-feedback>
            <div class="" v-if="setAddress">
              <p show dismissible class="text-danger">adres is niet correct</p>
            </div>
          </b-form-group>
        </div>
        <div class="col-12 col-md-6">
          <label for="house">Huisnummer</label>
          <b-form-input
            v-model="$v.temp_form.house_number.$model"
            type="text"
            id="house"
            :class="
              this.getErrorResponses.housenumber == false ? 'is-invalid' : ''
            "
            @input="handleFieldInput('house_number')"
            :state="temp_form.house_number ? houseNumberValidation : null"
            placeholder="bv. 12"
          ></b-form-input>
          <b-form-invalid-feedback
            v-if="
              !$v.temp_form.house_number.required ||
              !getErrorResponses.housenumber
            "
          >
            Huisnummer niet correct
          </b-form-invalid-feedback>
        </div>
      </b-row>
      <p class="error-feedback" v-if="setAddress">
        Combinatie van postcode en huisnummer is niet correct
      </p>
      <b-row>
        <div class="col-12">
          <label for="rentalOrOwner">Heb je een huur- of koopwoning?</label>
          <div class="btnDiv mb-2">
            <label class="btn btn-lg col-6 p-0 m-0"
              ><input
                value="4000"
                v-model="temp_form.koop"
                type="radio"
                name="toggle"
              /><span>Koopwoning</span></label
            >
            <label class="btn btn-lg col-6 p-0 m-0"
              ><input
                value="4003"
                v-model="temp_form.koop"
                type="radio"
                name="toggle"
              /><span>Huurwoning</span></label
            >
          </div>
        </div>
        <div class="col-12 mt-2" v-if="temp_form.koop == 4003">
          <label for="rentalOrOwner"
            >Is er toestemming van de verhuurder om een airco te
            plaatsen?</label
          >
          <div class="btnDiv mb-2">
            <label class="btn btn-lg col-6 p-0 m-0"
              ><input
                value="4064"
                v-model="temp_form.plaatsen"
                type="radio"
                name="plaatsen"
              /><span>Ja</span></label
            >
            <label class="btn btn-lg col-6 p-0 m-0"
              ><input
                value="null"
                v-model="temp_form.plaatsen"
                type="radio"
                name="plaatsen"
              /><span>Nee</span></label
            >
          </div>
        </div>
      </b-row>

      <b-row>
        <b-col lg="12" class="mt-3"
          ><b-button
            size="lg"
            type="submit"
            variant="success"
            class="w-100 py-3"
            style="background: #759e62; border: #74a200"
          >
            <span
              ><b>
                Bekijk of mijn woning <br />
                in aanmerking komt
              </b></span
            ></b-button
          ></b-col
        ></b-row
      >
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength, alphaNum } from "vuelidate/lib/validators";
export default {
  name: "FirstForm",
  mixins: [validationMixin],
  data() {
    return {
      validationCheck: true,
      setAddress: false,
      houseRegex: /^[0-9A-Za-z\s\-]+$/,

      temp_form: {
        zip: "",
        house_number: "",
        koop: "4000",
        plaatsen: "4064",
      },
    };
  },
  methods: {
    ...mapActions(["setFirstFormData", "getLocation"]),
    handleFieldInput(fieldName) {
      console.log("phone number changed", fieldName);
      if (this.getErrorResponses && !this.getErrorResponses[fieldName]) {
        console.log("phone number changed2", fieldName);
        this.$store.commit("setError", {
          ...this.getErrorResponses,
          [fieldName]: true,
        });
      }
    },
    onSubmit() {
      if (this.validationCheck) {
        const houseNumberWithoutPunctuation =
          this.temp_form.house_number.replace(/[\s-]/g, "");
        this.getLocation({
          ...this.temp_form,
          house_number: houseNumberWithoutPunctuation,
          onSuccess: () => {
            this.setFirstFormData(this.temp_form);
          },
          onError: () => {
            this.setAddress = true;
            setTimeout(() => {
              this.setAddress = false;
            }, 2000);
          },
        });
      }
    },
    validateState(zip) {
      const { $dirty, $error } = this.$v.temp_form[zip];
      return $dirty ? !$error : null;
    },
    validateState(house_number) {
      const { $dirty, $error } = this.$v.temp_form[house_number];
      return $dirty ? !$error : null;
    },
  },
  validations: {
    temp_form: {
      house_number: {
        required,
        alphaNum,
      },
    },
  },
  computed: {
    ...mapGetters(["getErrorResponses"]),
    zipValidation() {
      if (this.temp_form.zip == "") {
        this.validationCheck = false;
        return null;
      }
      let zipRegex = /^([0-9]{4}\s?[a-zA-Z]{2})$/;
      if (this.temp_form.zip.match(zipRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    houseNumberValidation() {
      return (
        this.houseRegex.test(this.temp_form.house_number) ||
        this.temp_form.house_number === ""
      );
    },
    isValidForm() {
      return this.zipValidation && this.houseNumberValidation;
    },
  },
};
</script>

<style>
.btnDiv {
  border: 2px solid #69326d;
  border-radius: 6px;
}
label.btn {
  padding: 0;
}

label.btn input {
  opacity: 0;
  position: absolute;
}

label.btn span {
  text-align: center;
  border-radius: 3px;
  padding: 12px 12px;
  display: block;
  margin: -2px;
}

label.btn input:checked + span {
  background-color: #69326d;
  color: #fff;
  font-weight: 400;
}
.error-feedback {
  width: 100%;
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 0.875em;
  color: #dc3545;
}
</style>
