<template>
  <div class="py-4">
    <b-container>
      <div class="pb-2 pb-md-4 text-center">
        <h3 class="font-weight-bold heading">
          Waarom kiezen voor airconditioning?
        </h3>
      </div>
      <b-row class="text-center">
        <!-- card1 -->
        <div class="col-12 col-md-6 col-lg-3">
          <div class="py-3">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="far"
              data-icon="air-conditioner"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
              class="sgv--color svg-sizing"
            >
              <path
                fill="currentColor"
                d="M437.42,309.5A16.1,16.1,0,0,0,416,324.67v17.62c0,6.38,3.93,11.85,9.66,14.63,14.92,7.25,24.72,23.06,21.84,41.47C444.4,418.12,426.2,432,406.22,432H400a40,40,0,0,1-40-40V288H312V392a88,88,0,0,0,88,88h4.53c44.92,0,85.21-32.18,90.77-76.76A87.55,87.55,0,0,0,437.42,309.5ZM216,424a40,40,0,0,1-40,40h-6.22c-20,0-38.18-13.88-41.28-33.61-2.88-18.41,6.92-34.22,21.84-41.47,5.73-2.78,9.66-8.25,9.66-14.63V356.67a16.1,16.1,0,0,0-21.42-15.17A87.55,87.55,0,0,0,80.7,435.24C86.26,479.82,126.55,512,171.47,512H176a88,88,0,0,0,88-88V288H216ZM544,0H32A32,32,0,0,0,0,32V224a32,32,0,0,0,32,32H544a32,32,0,0,0,32-32V32A32,32,0,0,0,544,0ZM528,208H48V48H528ZM112,176H464a16,16,0,0,0,16-16V144a16,16,0,0,0-16-16H112a16,16,0,0,0-16,16v16A16,16,0,0,0,112,176Z"
              ></path>
            </svg>
            <h6 class="font-weight-bold pt-3">
              Heerlijk koel huis in de zomer
            </h6>
            <p class="text-muted m-0">
              <small>
                Met een airco zorg je ervoor dat er binnenshuis altijd een
                constante temperatuur is die jij het prettigst vindt.
              </small>
            </p>
            <br />
          </div>
        </div>
        <!-- card2 -->
        <div class="col-12 col-md-6 col-lg-3">
          <div class="py-3">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="far"
              data-icon="heat"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              class="sgv--color svg-sizing"
            >
              <path
                fill="currentColor"
                d="M48,156.12V112A16,16,0,0,0,32,96H16A16,16,0,0,0,0,112v44.12A186.57,186.57,0,0,0,54.67,288.6,140.87,140.87,0,0,1,96,387.88V464a16,16,0,0,0,16,16h16a16,16,0,0,0,16-16V387.88A186.61,186.61,0,0,0,89.33,255.39,140.83,140.83,0,0,1,48,156.12Zm152,0V48a16,16,0,0,0-16-16H168a16,16,0,0,0-16,16V156.12A186.57,186.57,0,0,0,206.67,288.6,140.87,140.87,0,0,1,248,387.88V464a16,16,0,0,0,16,16h16a16,16,0,0,0,16-16V387.88a186.61,186.61,0,0,0-54.67-132.49A140.83,140.83,0,0,1,200,156.12Zm193.33,99.27A140.83,140.83,0,0,1,352,156.12V112a16,16,0,0,0-16-16H320a16,16,0,0,0-16,16v44.12A186.57,186.57,0,0,0,358.67,288.6,140.87,140.87,0,0,1,400,387.88V464a16,16,0,0,0,16,16h16a16,16,0,0,0,16-16V387.88A186.61,186.61,0,0,0,393.33,255.39Z"
                class=""
              ></path>
            </svg>
            <h6 class="font-weight-bold pt-3">Verwarmen in de winter</h6>
            <p class="text-muted m-0">
              <small>
                Veel airconditionings niet alleen kunnen koelen, maar ook
                uitermate geschikt om je ruimtes te verwarmen.
              </small>
            </p>
          </div>
        </div>
        <!-- card3 -->
        <div class="col-12 col-md-6 col-lg-3">
          <div class="py-3">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="far"
              data-icon="fan"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              class="sgv--color svg-sizing"
            >
              <path
                fill="currentColor"
                d="M256 224a32 32 0 1 0 32 32 32 32 0 0 0-32-32zm255.69 41.06C501 180.11 428.72 116 343.53 116a244.34 244.34 0 0 0-42.5 3.63l7.78-77.15A38.8 38.8 0 0 0 265.09.34C180.09 11 116 83.31 116 168.49a244.42 244.42 0 0 0 3.63 42.5l-77.16-7.78a38.4 38.4 0 0 0-31.38 11.43A39.06 39.06 0 0 0 .31 246.92C11 331.92 83.28 396 168.47 396a244.34 244.34 0 0 0 42.5-3.63l-7.78 77.15a38.25 38.25 0 0 0 11.44 31.35A39 39 0 0 0 242 512a38.44 38.44 0 0 0 5-.31c84.91-10.69 149-82.97 149-168.15a244.42 244.42 0 0 0-3.63-42.5l77.16 7.78a38.5 38.5 0 0 0 31.34-11.43 39 39 0 0 0 10.82-32.31zM324.75 246l13 35.91c6.78 18.65 10.22 39.4 10.22 61.65 0 57.25-40.47 106.4-95.84 118.59L266 324.76l-35.9 13c-18.63 6.81-39.38 10.24-61.63 10.24-57.25 0-106.38-40.47-118.59-95.84l137.37 13.88-13-35.91c-6.78-18.65-10.22-39.4-10.22-61.65 0-57.25 40.47-106.37 95.84-118.59L246 187.27l35.9-13c18.66-6.78 39.41-10.22 61.66-10.22 57.25 0 106.41 40.47 118.59 95.84z"
                class=""
              ></path>
            </svg>
            <h6 class="font-weight-bold pt-3">
              Constante toevoer frisse lucht
            </h6>
            <p class="text-muted m-0">
              <small>
                Naast de perfecte temperatuur zorgt een airco ook voor een
                gezonde luchtvochtigheid in huis.
              </small>
            </p>
            <br />
          </div>
        </div>
        <!-- card4 -->
        <div class="col-12 col-md-6 col-lg-3">
          <div class="py-3">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="far"
              data-icon="home"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
              class="sgv--color svg-sizing"
            >
              <path
                fill="currentColor"
                d="M570.24 247.41L512 199.52V104a8 8 0 0 0-8-8h-32a8 8 0 0 0-7.95 7.88v56.22L323.87 45a56.06 56.06 0 0 0-71.74 0L5.76 247.41a16 16 0 0 0-2 22.54L14 282.25a16 16 0 0 0 22.53 2L64 261.69V448a32.09 32.09 0 0 0 32 32h128a32.09 32.09 0 0 0 32-32V344h64v104a32.09 32.09 0 0 0 32 32h128a32.07 32.07 0 0 0 32-31.76V261.67l27.53 22.62a16 16 0 0 0 22.53-2L572.29 270a16 16 0 0 0-2.05-22.59zM463.85 432H368V328a32.09 32.09 0 0 0-32-32h-96a32.09 32.09 0 0 0-32 32v104h-96V222.27L288 77.65l176 144.56z"
                class=""
              ></path>
            </svg>
            <h6 class="font-weight-bold pt-3">Tegenwoordig heel duurzaam</h6>
            <p class="text-muted m-0">
              <small>
                Vroeger waren airco's echte energieslurpers en zag je het
                gebruik direct terug op de energierekening.
              </small>
            </p>
            <br />
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "WaaromZonnepanelen",
};
</script>

<style>
.font-weight-bold {
  font-weight: 700 !important;
}
.heading {
  color: #69326d !important;
}
.sgv--color {
  color: #759e62 !important;
}
.svg-sizing {
  height: 1em;
  vertical-align: -0.125em;
  font-size: 4em;
}
</style>
