<template>
  <div>
    <b-container class="py-md-4 py-3">
      <b-row cols=" py-0 py-md-4">
        <div class="col-12 col-lg-6">
          <a href="#"><img src="../../assets/logo.png" /></a>
          <h3 class="banner__heading font-weight-bold m-0">
            Goed nieuws! Jouw woning
            <span class="text-secondary">
              {{ this.address?.street }}
              {{ this.address?.streetNumber }}{{ this.address?.premise
              }}<!----></span
            >
            in
            <span class="text-secondary">{{ this.address?.settlement }} </span>
            komt in aanmerking voor gratis airco offertes.
          </h3>
          <div class="my-2 my-md-4">
            <div>
              <GmapMap
                :options="{
                  zoomControl: false,
                  fullscreenControl: false,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: true,
                  disableDefaultUi: false,
                }"
                :center="{ lat: this.lat, lng: this.lng }"
                :zoom="16"
                map-type-id="terrain"
                style="width: 100%; height: 250px"
              >
                <GmapMarker
                  :position="{
                    lat: this.lat,
                    lng: this.lng,
                  }"
                  :clickable="true"
                  :draggable="true"
                />
              </GmapMap>
            </div>
          </div>
          <CheckList />
        </div>
        <!--  -->
        <div class="col-12 col-lg-6 mt-lg-0 mt-4">
          <div class="bg-white shadow p-3 px-md-4 py-md-5 banner__form">
            <SecondForm />
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SecondForm from "./SecondForm.vue";
import CheckList from "./CheckList.vue";
export default {
  name: "SecondFormSection",
  components: { SecondForm, CheckList },
  computed: mapGetters(["lng", "lat", "first_form", "address"]),
};
</script>

<style lang="scss" scoped>
.text-secondary {
  color: #74a200 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.banner {
  &__form {
    border-radius: 8px !important;
    border-left: 2px solid #eee !important;
    border-color: #74a200 !important;
  }
  &__heading {
    font-size: 26px !important;
  }
  @media (max-width: 767.99px) {
    &__heading {
      font-size: 24px !important;
    }
  }
}
</style>
