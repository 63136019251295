<template>
  <b-container
    fluid
    class="guide position-relative z-1"
    :class="{ 'py-5 py-md-0': showSecondForm }"
  >
    <b-container
      class="py-5"
      :class="{ 'd-none': showSecondForm, 'd-md-block': showSecondForm }"
    >
      <b-row>
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="d-flex">
            <h1 class="count">01.</h1>
            <div
              class="img-fluid ml-5 ml-md-3 w-100 d-flex align-items-center justify-content-center"
            >
              <img src="../../assets/Arrow.png" class="w-100" />
            </div>
          </div>
          <p class="fs--18 fw--700">
            Beantwoord enkele<br />
            korte vragen
          </p>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="d-flex">
            <h1 class="count">02.</h1>
            <div
              class="img-fluid ml-5 ml-md-3 w-100 d-flex align-items-center justify-content-center"
            >
              <img src="../../assets/Arrow.png" class="w-100" />
            </div>
          </div>
          <p class="fs--18 fw--700">
            Wij checken de mogelijkheden voor jouw woning
          </p>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="d-flex">
            <h1 class="count">03.</h1>
            <div
              class="img-fluid ml-5 ml-md-3 w-100 d-flex align-items-center justify-content-center"
            >
              <img src="../../assets/Arrow.png" class="w-100" />
            </div>
          </div>
          <p class="fs--18 fw--700">
            Ontvang gratis en vrijblijvend tenminste 3 offertes van lokale
            specialisten
          </p>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="d-flex">
            <h1 class="count">04.</h1>
            <div
              class="img-fluid ml-5 ml-md-3 w-100 d-flex align-items-center justify-content-center h-72"
            >
              <img src="../../assets/Arrow.png" class="w-100 d-md-none" />
            </div>
          </div>
          <p class="fs--18 fw--700">Vergelijk en vind de beste specialist</p>
        </div>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
export default {
  name: "GuideSection",
  props: {
    showSecondForm: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.guide {
  background-color: #e4efc5;
}
.count {
  font-size: 60px;
  @media (max-width: 567.98px) {
    font-size: 40px;
  }
}
.h-72 {
  height: 72px;
  @media (max-width: 567.98px) {
    height: auto;
  }
}
</style>
