import Repository from "./Repository";
import LeadNewRepository from "./LeadNewRepository";
const LEADS = "leads";
import ZonnepanelenRepository from "./ZonnepanelenRepository";

import qs from "qs";

export default {
  postLead(payload) {
    // console.log('payload: ', payload);
    // console.log('payload after: ', qs.stringify(payload));
    return Repository.post(`${LEADS}`, qs.stringify(payload));
  },
  postNewLead(payload) {
    // console.log('payload: ', payload);
    // console.log('payload after: ', qs.stringify(payload));
    return LeadNewRepository.post(`${LEADS}`, payload);
  },
  postZonnepanelenLead(payload) {
    return ZonnepanelenRepository.post(`leads`, payload);
  },
};
