<template>
  <b-container class="main-cont mb-3">
    <div v-if="thirdQuestion.activeStep == 1">
      <div>
        <h2 class="d-inline title">
          In welke vormen van isolatie ben jij geïnteresseerd?
        </h2>
        <p class="d-inline fw--500 title">(meerdere antwoorden mogelijk)</p>
        <b-form class="mt-3" @submit.prevent="stepFour">
          <!-- Checkboxes with custom style -->
          <b-form-checkbox id="spouwmuur" required v-model="thirdQuestion.selectedOption" value="4579"
            class="mt-2">Spouwmuur</b-form-checkbox>
          <b-form-checkbox id="dak" value="4563" required v-model="thirdQuestion.selectedOption"
            class="mt-2">Dak</b-form-checkbox>
          <b-form-checkbox id="vloer" value="4576" required v-model="thirdQuestion.selectedOption"
            class="mt-2">Vloer</b-form-checkbox>
          <b-form-checkbox id="kozijnen" value="4566" required v-model="thirdQuestion.selectedOption"
            class="mt-2">Bodem</b-form-checkbox>

          <!-- <b-form-checkbox id="anders" value="Weet ik nog niet / anders" required class="mt-2">Weet ik nog niet
                        / anders</b-form-checkbox> -->
          <div class="mt-4">
            <button type="submit" class="rounded-pill btnForm px-5 py-2 mt-2">
              Volgende
            </button>
          </div>
        </b-form>
      </div>
    </div>
    <div v-else-if="thirdQuestion.activeStep == 2">
      <b-form>
        <div class="mb-5">
          <h2 class="fs--28 title">Om hoeveel kozijnen gaat het</h2>
          <div v-if="thirdQuestion.loader" class="spinner-border text-info fs--14 mt-3" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </b-form>
    </div>
    <div v-else-if="thirdQuestion.activeStep == 3">
      <b-form class="mt-3" @submit.prevent="goToNextStep">
        <h2 class="d-inline title">Hoe wil je het dak isoleren?</h2>
        <b-form-group class="mt-4">
          <b-form-radio required v-model="thirdQuestion.insulateRoof" name="ownership" value="4445">
            Binnenkant
          </b-form-radio>
          <b-form-radio required v-model="thirdQuestion.insulateRoof" name="ownership" value="4448" class="my-3">
            Buitenkant
          </b-form-radio>
        </b-form-group>
        <p>
            Let op: dakisolatie is alleen mogelijk aan de binnenkant, niet aan
            de buitenkant
          </p>
        <div class="mt-4">
          <button type="submit" class="rounded-pill btnForm px-5 py-2 mt-2">
            Volgende
          </button>
        </div>
      </b-form>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "ThirdQuestion",
  props: {
    step: {
      type: Number,
    },
    thirdQuestion: {
      type: Object,
    },
    fifthQuestion: {
      type: Object,
    },
  },

  methods: {
    stepFour() {
    if(this.thirdQuestion.selectedOption.length>0){
      console.log(this.thirdQuestion.selectedOption, 'Third Question checkbox')
      console.log(this.thirdQuestion.insulateRoof, 'Third Question insulate roof')
      if (this.thirdQuestion.selectedOption.includes("4566") && this.thirdQuestion.selectedOption.length == 1) {
        this.$emit("gotoResult", 6);
      } else {
        this.$emit("gotoStepFour", 4);
      }
    }else{
      alert("Its mandatory to choose atleast one!")
    }
    },
    goToNextStep() {
      console.log(this.thirdQuestion.selectedOption, 'Third Question checkbox')
      console.log(this.thirdQuestion.insulateRoof, 'Third Question insulate roof')
      this.fifthQuestion.activeStep = true;
      if (this.thirdQuestion.selectedOption.includes("4566") && this.thirdQuestion.selectedOption.length == 1) {
        this.$emit("gotoResult", 6);
      } else {
        this.$emit("gotoStepFifth", 5);
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
