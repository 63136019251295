<template>
  <div
    class="InsultHome p-1"
    :class="{ 'd-none': showSecondForm, 'd-md-block': showSecondForm }"
  >
    <b-container>
      <b-row class="cards-section">
        <div class="col-lg-4 col-md-4 mt-2 col-sm-12">
          <div class="card h-100">
            <div class="card-body">
              <div class="img-fluid mt-2">
                <img src="../../assets/Ellipse.png" class="w-25" />
              </div>
              <p class="text-justify card-text">
                <b>Offertevergelijker helpt</b> consumenten met het vinden van
                de juiste en erkende specialisten in de buurt.<b class="ml-2"
                  >100% gratis en vrijblijvend!</b
                >
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 mt-2 col-sm-12">
          <div class="card h-100">
            <div class="card-body">
              <div class="img-fluid mt-2">
                <img src="../../assets/Ellipse.png" class="w-25" />
              </div>
              <p class="text-justify card-text">
                Inmiddels helpen wij <b>gemiddeld 250 consumenten per dag</b> en
                hebben we ruim 400 erkende partners aangesloten in 12
                verschillende branches! 
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 mt-2 col-sm-12">
          <div class="card h-100">
            <div class="card-body">
              <div class="img-fluid mt-2">
                <img src="../../assets/Ellipse.png" class="w-25" />
              </div>
              <p class="text-justify card-text">
                Door de offertes naast elkaar te leggen vind je eenvoudig de
                deal die bij jou past en <b>bespaar je tot wel 40%.</b>
              </p>
            </div>
          </div>
        </div>
        <button class="rounded-pill btnForm px-4 my-5 py-2 fw--700 d-md-none">
          Gratis woningcheck
        </button>
      </b-row>
      <b-row class="mid-section">
        <div class="col-md-5 col-sm-12">
          <h1 class="head">Waarom jouw woning isoleren?</h1>
          <!-- <p class="para fs--14">
            An mel corpora consectetuer. Duo veri eripuit honestatis ei.
            Mandamus expetenda has ex. Eu minim movet quodsi eum. Et vim dolore
            epicurei, ut aeque postea nam, at posse dicta.
          </p> -->
        </div>
        <div class="col-md-7 col-sm-12">
          <div class="card my-3">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <h4 class="fact-head">Besparing energiekosten</h4>
                <img src="../../assets/money-bag.png" />
              </div>
              <p class="fact-subhead">
                Bespaar honderden euro’s op jouw energiekosten
              </p>
            </div>
          </div>
          <div class="card my-3">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <h4 class="fact-head">Waardestijging woning</h4>
                <img src="../../assets/coins.png" />
              </div>
              <p class="fact-subhead">
                Na isolatie stijgt direct de waarde van jouw woning
              </p>
            </div>
          </div>
          <div class="card my-3">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <h4 class="fact-head">Korte terugverdientijd</h4>
                <img src="../../assets/bank-note.png" class="" />
              </div>

              <p class="fact-subhead">
                Gemiddelde terugverdientijd is minder dan 3 jaar
              </p>
            </div>
          </div>
        </div>
        <button class="rounded-pill btnForm px-4 my-5 py-2 fw--700 d-md-none">
          Gratis woningcheck
        </button>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "WhyInsultHome",
  props: {
    showSecondForm: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.InsultHome {
  background-color: #e7eee7;
}

.head {
  font-size: 50px;

  @media (max-width: 567.98px) {
    font-size: 30px;
  }
}

.para {
  color: #999999;
  font-size: 16px;
}

.fact-head {
  color: #86b37e;
  font-size: 32;
  font-weight: 700;
}
.fact-subhead {
  color: #999999;
  font-size: 18;
  font-weight: 400;
}
.mid-section {
  margin-top: 70px;
  @media (max-width: 992px) {
    padding: 0px !important;
  }
  @media (max-width: 768px) {
    margin-top: 10px;
  }
}
.cards-section {
  // z-index: 99;
  margin-top: -200px;
}
.card {
  border-radius: 13.35px;
  border: none;
}
.card-text {
  color: #525252;
}
.btnForm {
  max-width: 85%;
  margin: auto;
}
</style>
