<template>
  <b-container class="main-cont mb-3">
    <div v-if="firstQuestion.show">
      <b-form @submit.prevent="onSubmit">
        <div class="row">
          <h2 class="title">Vul jouw postcode en huisnummer in:</h2>
          <div class="col-lg-6 mt-3 p-0">
            <b-form-group
              id="Postcode"
              class="text"
              label="Postcode"
              label-for="Postcode"
            >
              <b-form-input
                required
                class="input"
                :class="this.getErrorResponses.zip == false ? 'is-invalid' : ''"
                v-model="firstQuestion.postCode"
                @input="handleFieldInput('zip')"
                :state="firstQuestion.postCode ? zipValidation : null"
                placeholder="1234 AB"
                type="text"
              ></b-form-input>
              <b-form-invalid-feedback v-if="!zipValidation">
                Moet minimaal 4 numerieke tekens en 2 tekens bevatten. postcode
                niet correct
              </b-form-invalid-feedback>
              <div class="" v-if="setAddress">
                <p show dismissible class="text-danger">
                  adres is niet correct
                </p>
              </div>
            </b-form-group>
          </div>
          <div class="col-lg-6 mt-3 p-0">
            <label class="d-block text">Huisnummer</label>
            <b-form-input
              required
              class="input"
              @input="handleFieldInput('house_number')"
              :class="
                this.getErrorResponses.housenumber == false ? 'is-invalid' : ''
              "
              v-model="firstQuestion.house_number"
              :state="firstQuestion.house_number ? housenumberValidation : null"
              placeholder="100"
            ></b-form-input>
            <b-form-invalid-feedback v-if="!housenumberValidation">
              <!-- Voer een geldige huisnr in -->
              Huisnummer niet correct
            </b-form-invalid-feedback>
          </div>
        </div>

        <div class="row">
          <!-- Emit an event to indicate button click -->

          <button
            type="submit"
            class="rounded-pill btnForm px-md-3 px-2 sm-bg-btn py-2 mt-4"
          >
            <div
              v-if="loader"
              class="spinner-border text-info fs--14"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
            <span v-else> Ophalen woninggegevens</span>
          </button>
        </div>
      </b-form>
    </div>
    <div v-else>
      <h2 class="title">Jouw adresgegevens:</h2>
      <p class="address text-wrap">
        {{ this.address.street }}

        {{ this.address.streetNumber }}
        {{ this.address.premise }},

        {{ this.address.postalCode }}
        in
        {{ this.address.settlement }}
      </p>
      <GoogleMapSection />
      <button
        @click="goToNextStep"
        class="rounded-pill btnForm sm-bg-btn px-2 px-md-5 py-2 mt-2"
      >
        Volgende
      </button>
    </div>
  </b-container>
</template>

<script>
import GoogleMapSection from "../GoogleMapSection.vue";
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
export default {
  name: "FirstQuestion",
  data() {
    return {
      zipRegex: /^([0-9]{4}\s?[a-zA-Z]{2})$/,
      houseRegex: /^[0-9A-Za-z\s\-]+$/,
      validationCheck: true,
      invalidAddress: false,
      loader: false,
      setAddress: false,
    };
  },
  mixins: [validationMixin],
  components: { GoogleMapSection },
  props: {
    postdata: {
      type: Object,
      required: true,
    },
    step: {
      type: Number,
    },
    firstQuestion: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions(["getLocation"]),
    handleFieldInput(fieldName) {
      console.log("phone number changed", fieldName);
      if (this.getErrorResponses && !this.getErrorResponses[fieldName]) {
        console.log("phone number changed2", fieldName);
        this.$store.commit("setError", {
          ...this.getErrorResponses,
          [fieldName]: true,
        });
      }
    },
    onSubmit() {
      this.loader = true;
      if (this.isValidForm) {
        const houseNumberWithoutPunctuation =
          this.firstQuestion.house_number.replace(/[\s-]/g, "");
        this.getLocation({
          zip: this.firstQuestion.postCode,
          house_number: houseNumberWithoutPunctuation,
          onSuccess: () => {
            this.firstQuestion.show = false;
            this.loader = false;
          },
          onError: () => {
            this.loader = false;
            this.setAddress = true;

            setTimeout(() => {
              this.setAddress = false;
            }, 5000);
          },
        });
      }
    },
    // onSubmit() {
    //   this.loader = true;
    //   if (this.isValidForm) {
    //     this.getLocation({
    //       zip: this.firstQuestion.postCode,
    //       house_number: this.firstQuestion.houseNumber,
    //       onSuccess: () => {
    //         this.firstQuestion.show = false;
    //         //this.goToNextStep();
    //         this.loader = false;
    //       },
    //       onError: () => {
    //         setTimeout(() => {}, 2000);
    //       },
    //     });
    //   }
    // },

    goToNextStep() {
      this.$emit("gotoStepTwo", this.step + 1);
      this.setAddress = false;
    },
    validateState() {
      const { $dirty, $error } = this.$v.firstQuestion.postCode;
      return $dirty ? !$error : null;
    },
    validateState(house_number) {
      const { $dirty, $error } = this.$v.firstQuestion.house_number;
      return $dirty ? !$error : null;
    },
  },
  computed: {
    ...mapGetters(["lat", "lng", "getErrorResponses", "address"]),
    zipValidation() {
      return (
        this.zipRegex.test(this.firstQuestion.postCode) ||
        this.firstQuestion.postCode === ""
      );
    },
    housenumberValidation() {
      return (
        this.houseRegex.test(this.firstQuestion.house_number) ||
        this.firstQuestion.house_number === ""
      );
    },
    isValidForm() {
      return this.zipValidation && this.housenumberValidation;
    },
  },
};
</script>
<style lang="scss" scoped>
.text {
  color: #676e80;
}

.input {
  background-color: #fcfcfc;
  border: 1px solid #e9eef3;
  border-radius: 10px;
  padding: 10px;
  width: 98%;
}

.address {
  font-size: 14px;
  color: #676e80;
  @media (max-width: 514px) {
    font-size: 12px !important;
  }
  @media (max-width: 432px) {
    font-size: 10px !important;
  }
  @media (max-width: 340px) {
    font-size: 9px !important;
  }
}

.address {
  font-size: 14px;
  color: #676e80;
  width: max-content;
}
</style>
