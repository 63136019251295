<template>
  <b-row class="">
    <b-col class="col-12 col-md-6 border-md-right">
      <h2 class="title">Goed nieuws!</h2>
      <h3 class="result text-site-secondary">
        Jouw woning op {{ `${this.address.street}` }}
        {{ this.address.streetNumber }}{{ this.address.premise }} in
        {{
          `
        ${this.address.settlement}`
        }}
        heeft recht op subsidie tot 30% en is geschikt voor woningisolatie.
      </h3>
      <div class="my-2 my-md-5">
        <div class="row align-items-center my-1 my-md-3">
          <div class="col-12 col-md-12 result-desc">
            <div class="d-flex">
              <span>
                <img
                  src="../../assets/Ellipse.png"
                  class="img-fluid result-desc__img"
                />
              </span>
              <span class="align-self-center">
                <p class="d-md-block d-none ml-2 mb-0">
                  Wij hebben meerdere specialisten in jouw regio gevonden.
                </p>
                <p class="d-md-none d-block ml-1 mb-0">
                  Wij hebben lokale specialisten gevonden
                </p>
              </span>
            </div>
          </div>
        </div>

        <div class="row align-items-center my-1 my-md-3">
          <div class="col-12 col-md-12 result-desc">
            <div class="d-flex">
              <span>
                <img
                  src="../../assets/Ellipse.png"
                  class="img-fluid result-desc__img"
                />
              </span>
              <span class="align-self-center">
                <p class="d-md-block d-none ml-2 mb-0">
                  Ontvang en vergelijk de beste offertes en bespaar direct
                </p>
                <p class="d-md-none d-block ml-1 mb-0">
                  Scherpe offertes staan klaar
                </p>
              </span>
            </div>
          </div>
        </div>

        <div class="row align-items-center my-1 my-md-3">
          <div class="col-12 col-md-12 result-desc mb-3 mb-md-0">
            <div class="d-flex">
              <span>
                <img
                  src="../../assets/Ellipse.png"
                  class="img-fluid result-desc__img"
                />
              </span>
              <span class="align-self-center">
                <p class="ml-2 mb-0 d-md-block d-none">
                  Jij komt in aanmerking voor extra korting tot wel 40%
                </p>
                <p class="ml-1 mb-0 d-md-none d-block">
                  Ontvang extra korting tot wel 40%
                </p>
              </span>
            </div>
          </div>
          <!-- <div class="col-2 col-md-1">
            <img src="../../assets/Ellipse.png" width="30" />
          </div>
          <div class="col-10 col-md-11 result-desc">
            
          </div> -->
        </div>
      </div>
      <div class="position-relative mb-5 mb-md-0">
        <GoogleMapSection />
        <div class="d-md-none d-block arrow-down text-center">
          <div class="arrow-down__design">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="12"
              viewBox="0 0 18 12"
              fill="none"
            >
              <path
                d="M0.529224 3.23931L8.31968 11.0298C8.69541 11.4055 9.30453 11.4055 9.68022 11.0297L17.4707 3.23931C17.8464 2.86362 17.8464 2.2545 17.4707 1.87877L16.562 0.970146C16.1869 0.595045 15.579 0.59434 15.2031 0.968541L8.99997 7.1426L2.79682 0.96854C2.42086 0.594339 1.81295 0.595044 1.43785 0.970145L0.529224 1.87877C0.153536 2.25446 0.153536 2.86358 0.529224 3.23931Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
    </b-col>
    <b-col class="col-md-6 col-sm-12 col-xs-12 mt-2">
      <div
        class="d-flex justify-content-center align-items-center w-100 h-100"
        v-if="userDetail.leadResponse"
      >
        <div>
          <h4 class="text-center">Bedankt!</h4>
          <img
            v-if="profId"
            referrerpolicy="no-referrer-when-downgrade"
            :src="`https://republish.verbeterthuis.nl/m/6047/b5a3e5956dd7/?event=7049&unique_conversion_id=${profId}`"
            style="width: 1px; height: 1px; border: 0px"
          />
          <p class="text-center">
            Wij hebben jouw aanvraag correct ontvangen. Jij ontvangt z.s.m.
            superscherpe offertes! Wij nemen contact op voor aanvullende
            informatie, indien nodig!
          </p>
        </div>
      </div>
      <div v-else>
        <h3 class="result-right-heading">
          Ontvang nu superscherpe offertes van de beste specialisten in jouw
          regio. 100% gratis en vrijblijvend.<span class="title-bonus">
            Bonus! Extra korting tot wel 40% mogelijk.</span
          >
        </h3>

        <b-form class="mt-1 mt-md-5" @submit.prevent="onsubmit">
          <div class="row mt-1 mt-md-5">
            <div class="col-12">
              <b-form-group
                class="text label"
                label="Op welke termijn wil jij isoleren?"
              >
                <b-form-select
                  v-model="userDetail.isolateTime"
                  placeholder="Selecteer wanneer.."
                >
                  <option value="" disabled>Selecteer wanneer..</option>
                  <option value="In overleg">In overleg</option>
                  <option value="Binnen 3 maanden">Binnen 3 maanden</option>
                  <option value="Binnen 3 tot 6 maanden">
                    Binnen 3 tot 6 maanden
                  </option>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-lg-6">
              <b-form-group
                id="Voornaam"
                class="text label"
                label="Voornaam"
                label-for="Voornaam"
              >
                <b-form-input
                  required
                  class="input"
                  @input="handleFieldInput('firstname')"
                  :class="
                    this.getErrorResponses.firstname == false
                      ? 'is-invalid'
                      : ''
                  "
                  v-model="userDetail.firstname"
                  :state="userDetail.firstname ? firstNameValidation : null"
                  placeholder="Lisa"
                  type="text"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="!userDetail.firstname || !getErrorResponses.firstname"
                >
                  Voornaam niet correct
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-lg-6">
              <b-form-group
                id="Achternaam"
                class="text label"
                label="Achternaam"
                label-for="Achternaam"
              >
                <b-form-input
                  required
                  :class="
                    this.getErrorResponses.lastname == false ? 'is-invalid' : ''
                  "
                  class="input"
                  @input="handleFieldInput('lastname')"
                  v-model="userDetail.lastname"
                  :state="userDetail.lastname ? lastNameValidation : null"
                  placeholder="de Jong"
                  type="text"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="!userDetail.lastname || !getErrorResponses.lastname"
                >
                  Achternaam niet correct
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <b-form-group
                id="Emailadres"
                class="text label"
                label="E-mailadres"
                label-for="Emailadres"
              >
                <b-form-input
                  :class="
                    this.getErrorResponses.email == false ? 'is-invalid' : ''
                  "
                  required
                  class="input"
                  @input="handleFieldInput('email')"
                  v-model="userDetail.email"
                  :state="userDetail.email ? emailValidation : null"
                  placeholder="lisa@dejong.nl"
                  type="email"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="!userDetail.email || !getErrorResponses.email"
                >
                  E-mailadres niet correct
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-lg-6">
              <b-form-group
                id="Telefoonnummer"
                class="text label"
                v-model="userDetail.phone_number"
                label="Telefoonnummer"
                label-for="Telefoonnummer"
              >
                <b-form-input
                  :class="
                    this.getErrorResponses.phone_number == false
                      ? 'is-invalid'
                      : ''
                  "
                  required
                  v-model="userDetail.phone_number"
                  @input="handleFieldInput('phone_number')"
                  class="input"
                  placeholder="0612345678"
                  id="tel"
                  :state="userDetail.phone_number ? phoneValidation : null"
                  type="text"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="
                    !userDetail.phone_number || !getErrorResponses.phone_number
                  "
                >
                  Telefoonnummer niet correct
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="my- my-md-5 mx-3">
              <button
                :disabled="getLoader"
                type="submit"
                class="rounded-pill btnForm px-5 py-2 mt-4"
              >
                <div
                  v-if="getLoader"
                  class="spinner-border text-info fs--14"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <span v-else>Afronden</span>
              </button>
            </div>
            <p class="mt-5 mx-3">
              Door gratis en vrijblijvend offertes aan te vragen ga je akkoord
              met onze <u>Algemene voorwaarden</u>
            </p>
          </div>
        </b-form>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import { mapGetters } from "vuex";
import GoogleMapSection from "./GoogleMapSection.vue";
export default {
  components: {
    GoogleMapSection,
  },
  name: "ResultForm",
  data() {
    return {
      phoneRegex: /^(0|\+31|0031|00 31)([-\s]?\d[-\s]?){9}$/,
      nameRegex: /^[a-zA-Z\s.,'-]{1,30}$/,
      emailRegex: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z]{2,}$/,
    };
  },
  props: {
    userDetail: {
      type: Object,
    },
    house_number: {
      type: String,
    },
    submit: {
      type: Function,
    },
  },
  methods: {
    handleFieldInput(fieldName) {
      console.log("phone number changed", fieldName);
      if (this.getErrorResponses && !this.getErrorResponses[fieldName]) {
        console.log("phone number changed2", fieldName);
        this.$store.commit("setError", {
          ...this.getErrorResponses,
          [fieldName]: true,
        });
      }
    },
    onsubmit() {
      console.log(this.isValidForm, "validaty");

      if (this.isValidForm) {
        // const phoneNumberWithoutPunctuation = this.userDetail.phone_number
        //   .replace(/[\s-]/g, "")
        //   .replace(/^00(\d+)$/, "$1");
        // this.userDetail.phone_number = phoneNumberWithoutPunctuation;
        this.$emit("submit");
      }
    },
    setupFacebookPixel() {
      let useScript = document.createElement("script");
      useScript.setAttribute(
        !(function (f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function () {
            n.callMethod
              ? n.callMethod.apply(n, arguments)
              : n.queue.push(arguments);
          };

          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = "2.0";

          n.queue = [];
          t = b.createElement(e);
          t.async = !0;

          t.src = v;
          s = b.getElementsByTagName(e)[0];

          s.parentNode.insertBefore(t, s);
        })(
          window,
          document,
          "script",

          "https://connect.facebook.net/en_US/fbevents.js"
        ),
        fbq("init", "969816563708997"),
        fbq("track", "PageView"),
        fbq("track", "Lead")
      ),
        document.head.appendChild(useScript);
    },
  },

  watch: {
    "userDetail.leadResponse": function (newVal, oldVal) {
      if (newVal) {
        this.setupFacebookPixel();
      }
    },
  },
  created() {
    document.title = "Offertevergelijker";
    this.noscriptHtml =
      '<noscript><img height="1" width="1" style="display:none"src="https://www.facebook.com/tr?id=969816563708997&ev=PageView&noscript=1"/></noscript>';
  },
  computed: {
    ...mapGetters([
      "lat",
      "lng",
      "getLoader",
      "getErrorResponses",
      "address",
      "profId",
    ]),

    phoneValidation() {
      return (
        this.phoneRegex.test(this.userDetail.phone_number) ||
        this.userDetail.phone_number === ""
      );
    },
    firstNameValidation() {
      return (
        this.nameRegex.test(this.userDetail.firstname) ||
        this.userDetail.firstname === ""
      );
    },
    lastNameValidation() {
      return (
        this.nameRegex.test(this.userDetail.lastname) ||
        this.userDetail.lastname === ""
      );
    },
    emailValidation() {
      return (
        this.emailRegex.test(this.userDetail.email) ||
        this.userDetail.email === ""
      );
    },
    isValidForm() {
      return (
        this.phoneValidation &&
        this.firstNameValidation &&
        this.lastNameValidation &&
        this.emailValidation
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.input {
  background-color: #fcfcfc;
  border: 1px solid #e9eef3;
  border-radius: 10px;
  padding: 15px;
  width: 98%;
  -moz-appearance: textfield;
}

.arrow-down {
  position: absolute;
  left: 42%;

  bottom: -34%;

  &__design {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;

    background: #f8b748;
    border-radius: 50%;
  }
}

.custom-select {
  background-color: #fcfcfc !important;
  border: 1px solid #e9eef3 !important;
  border-radius: 10px;
}

.result-desc {
  color: #525252 !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  @media (max-width: 567.98px) {
    font-size: 12.5px !important;
    line-height: 14px;
  }

  &__img {
    width: 30px !important;

    @media (max-width: 567.98px) {
      width: 20px !important;
    }
  }
}

.input::-webkit-inner-spin-button,
.input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.title {
  color: #86b37e !important;
}

.title-bonus {
  color: #86b37e !important;

  @media (max-width: 567.98px) {
    font-size: 15px !important;
  }
}

.result {
  font-size: 24px;
  font-weight: 700;
  line-height: 32.6px;

  @media (max-width: 567.98px) {
    font-size: 15px;
    line-height: 17px;
  }

  &-dics {
    font-size: 16px;

    @media (max-width: 567.98px) {
      font-size: 13px;
    }
  }
}

.text-site-secondary {
  color: #333333;
}

.border-md-right {
  border-right: 1px solid #dee2e6 !important;

  @media (max-width: 567.98px) {
    border-right: none !important;
  }
}

.result-right-heading {
  font-size: 18px;
  font-weight: 700;

  @media (max-width: 567.98px) {
    font-size: 15px;
  }
}

.label {
  color: #676e80 !important;
}
</style>
