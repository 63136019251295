<template>
  <b-container class="main-cont mb-3">
    <div v-if="fifthQuestion.activeStep">
      <b-form @submit.prevent="goToNextStep()">
        <div>
          <h2 class="title">Om welk soort dak gaat het?</h2>
          <b-form-group class="mt-4">
            <b-form-radio
              required
              v-model="fifthQuestion.roof"
              name="ownership"
              value="4433"
            >
              Plat
            </b-form-radio>
            <b-form-radio
              required
              v-model="fifthQuestion.roof"
              name="ownership"
              value="4436"
              class="my-3"
            >
              Schuin
            </b-form-radio>
          </b-form-group>
          <p>
            Let op: dakisolatie is alleen mogelijk aan de binnenkant, niet aan
            de buitenkant
          </p>
        </div>
        <div>
          <button type="submit" class="rounded-pill sm-bg-btn  btnForm px-2 px-md-5 py-2 mt-2">
            Volgende
          </button>
        </div>
      </b-form>
    </div>
    <div v-else>
      <h2 class="title">Om hoeveel kozijnen gaat het?</h2>
      <b-form @submit.prevent="goToNextStep">
        <div class="col-lg-6 mt-3 p-0">
          <label class="d-block text">Vul een getal in (1-9)</label>
          <b-form-input
            required
            :state="fifthQuestion.frame ? numberValidation : null"
            class="input"
            v-model="fifthQuestion.frame"
            type="number"
          ></b-form-input>
        </div>
        <div class="mt-4">
          <button type="submit" class="rounded-pill btnForm px-5 py-2 mt-2">
            Ga naar het resultaat
          </button>
        </div>
      </b-form>
    </div>
  </b-container>
</template>

<script>
import { BFormInput } from "bootstrap-vue";
export default {
  name: "FifthQuestion",
  data() {
    return {
      numberRegex: /^[1-9]$/,
    };
  },
  props: {
    step: {
      type: Number,
    },
    fifthQuestion: {
      type: Object,
    },
    thirdQuestion: {
      type: Object,
    },
  },
  methods: {
    goToNextStep() {
      console.log(this.fifthQuestion,'fifth question')
      if (
        this.thirdQuestion.selectedOption.includes("4412") &&
        this.fifthQuestion.activeStep
      ) {
        this.fifthQuestion.activeStep = false;
        // this.$emit("gotoStepFifth", this.step + 1);
      } else {
        if (this.isValidForm) {
          this.$emit("gotoResult", this.step + 1);
        }
      }
    },
  },
  computed: {
    numberValidation() {
      return (
        this.numberRegex.test(this.fifthQuestion.frame) ||
        this.fifthQuestion.frame === ""
      );
    },
    isValidForm() {
      return this.numberValidation;
    },
  },
};
</script>
<style lang="scss" scoped>
.input {
  background-color: #fcfcfc;
  border: 1px solid #e9eef3;
  border-radius: 10px;
  padding: 10px;
  width: 98%;
  -moz-appearance: textfield;
  /* Firefox */
}

.input::-webkit-inner-spin-button,
.input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
