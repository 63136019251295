<template>
  <div class="py-4">
    <b-container>
      <div class="pb-2 pb-md-4 text-center">
        <h3 class="font-weight-bold">Waarom zonnepanelen?</h3>
      </div>
      <b-row class="text-center">
        <!-- card1 -->
        <div class="col-12 col-md-6 col-lg-4">
          <div class="py-3">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="far"
              data-icon="piggy-bank"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
              class="sgv--color svg-sizing"
            >
              <path
                fill="currentColor"
                d="M560 224h-29.5c-11.1-25.3-28.7-46.9-50.5-63.4V96h-16c-30.3 0-57.8 10.1-81 26.2.4-3.4 1-6.7 1-10.2C384 50.1 333.9 0 272 0S160 50.1 160 112c0 9.7 1.5 19 3.8 27.9C114.9 159.8 78 203.1 67.2 256H56c-14.8 0-26.5-13.5-23.5-28.8C34.7 215.8 45.4 208 57 208h1c3.3 0 6-2.7 6-6v-20c0-3.3-2.7-6-6-6-28.5 0-53.9 20.4-57.5 48.6C-3.9 258.8 22.7 288 56 288h8c0 52.2 25.4 98.1 64 127.3V496c0 8.8 7.2 16 16 16h112c8.8 0 16-7.2 16-16v-48h64v48c0 8.8 7.2 16 16 16h112c8.8 0 16-7.2 16-16v-80.9c11.7-9 22.4-19.3 31.3-31.1H560c8.8 0 16-7.2 16-16V240c0-8.8-7.2-16-16-16zM272 48c35.3 0 64 28.7 64 64 0 5.6-.9 10.9-2.3 16H224c-4.5 0-8.8 1-13.3 1.3-1.6-5.5-2.7-11.3-2.7-17.3 0-35.3 28.7-64 64-64zm256 288h-40.6c-23.5 31.1-19.4 27.9-55.4 55.4V464h-48v-64H224v64h-48v-72.6c-14.6-11-64-39.8-64-103.4 0-61.8 50.2-112 112-112h167.4c9.5-7.6 22.2-19.3 40.6-26.2v34.7c45.4 34.4 44.2 35.2 67.1 87.5H528v64zm-96-80c-8.8 0-16 7.2-16 16s7.2 16 16 16 16-7.2 16-16-7.2-16-16-16z"
                class=""
              ></path>
            </svg>
            <h6 class="font-weight-bold pt-3">Jaarlijkse besparing</h6>
            <p class="text-muted m-0">
              <small>
                Jaarlijks bespaar je honderden euro’s op de energierekening met
                zonnepanelen.
              </small>
            </p>
            <br />
          </div>
        </div>
        <!-- card2 -->
        <div class="col-12 col-md-6 col-lg-4">
          <div class="py-3">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="far"
              data-icon="sun-cloud"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
              class="sgv--color svg-sizing"
            >
              <path
                fill="currentColor"
                d="M580.5 226c-18.7-39.6-58.6-66-104.5-66-42.4 0-80 23.4-100.2 58.2-49.4 5.7-87.8 47.7-87.8 98.6 0 54.7 44.5 99.2 99.2 99.2h153.6c54.7 0 99.2-44.5 99.2-99.2 0-40.6-24.3-75.5-59.5-90.8zm-39.7 142H387.2c-28.3 0-51.2-22.9-51.2-51.2s22.9-51.2 51.2-51.2c7.7 0 14.8 2.2 21.3 5.3 2.7-35.1 31.7-62.9 67.5-62.9 34.1 0 62 25.1 67 57.8 27.2 1.2 49 23.4 49 51 0 28.3-22.9 51.2-51.2 51.2zM256 458l-51.9-77-90.9 17.6 17.6-91.2-76.8-52 76.8-52-17.6-91.2 91 17.6L256 53l51.9 76.9 91-17.6-8.4 43.5c16-11.5 34.2-19.8 53.5-24.1l4.1-21.3c2.6-13.2-1.6-26.8-11.1-36.4-9.6-9.5-23.2-13.7-36.2-11.1l-70.9 13.7-40.4-59.9c-15.1-22.3-51.9-22.3-67 0l-40.4 59.9-70.8-13.7C98 60.4 84.5 64.5 75 74.1c-9.5 9.6-13.7 23.1-11.1 36.3l13.7 71-59.8 40.5C6.6 229.5 0 242 0 255.5s6.7 26 17.8 33.5l59.8 40.5-13.7 71c-2.6 13.2 1.6 26.8 11.1 36.3 9.5 9.5 22.9 13.7 36.3 11.1l70.8-13.7 40.4 59.9C230 505.3 242.6 512 256 512s26-6.7 33.5-17.8l40.4-59.9c-14.6-7.2-27.7-16.8-38.7-28.6L256 458zm0-258c17.1 0 32.3 7.9 42.6 20.1 11.8-10.8 25.8-19.3 41.1-25.3-19-25.8-49.3-42.8-83.7-42.8-57.3 0-104 46.7-104 104s46.7 104 104 104c2.6 0 5.1-.6 7.7-.8-4.6-13.4-7.7-27.5-7.7-42.4 0-1.6.4-3.2.4-4.8h-.4c-30.9 0-56-25.1-56-56s25.1-56 56-56z"
                class=""
              ></path>
            </svg>
            <h6 class="font-weight-bold pt-3">Hele jaar rendement</h6>
            <p class="text-muted m-0">
              <small>
                Zonnepanelen werken perfect in de zomer, maar ook op bewolkte
                koude dagen.
              </small>
            </p>
          </div>
        </div>
        <!-- card3 -->
        <div class="col-12 col-md-6 col-lg-4">
          <div class="py-3">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="far"
              data-icon="tree-alt"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              class="sgv--color svg-sizing"
            >
              <path
                fill="currentColor"
                d="M463.16 198.09a94.96 94.96 0 0 0 2.44-21.36c0-58.56-51.97-105.42-111.06-99.91C343.81 32.81 303.69 0 256 0s-87.81 32.81-98.53 76.83c-59.09-5.52-111.06 41.34-111.06 99.91 0 7.19.81 14.33 2.44 21.36C18.88 215.89 0 247.86 0 283.64 0 338.98 45.47 384 101.34 384H224v56.45l-30.29 48.4c-5.32 10.64 2.42 23.16 14.31 23.16h95.96c11.89 0 19.63-12.52 14.31-23.16L288 440.45V384h122.66C466.53 384 512 338.98 512 283.64c0-35.78-18.88-67.75-48.84-85.55zM410.66 336H101.34C71.94 336 48 312.52 48 283.64c0-22.95 15.22-42.95 37.84-49.75l27.59-8.28-13.12-25.64c-3.94-7.64-5.91-15.45-5.91-23.23 0-28.88 23.91-52.38 53.31-52.38 7.41 0 14.78 1.61 21.94 4.77l37.53 16.66-4.53-45.42C202.66 71.48 226.59 48 256 48s53.34 23.48 53.19 53.38l-4.38 44.41 37.53-16.66c35.59-15.72 75.25 11.45 75.25 47.61 0 7.78-1.97 15.59-5.91 23.23l-13.12 25.64 27.59 8.28c22.62 6.8 37.84 26.8 37.84 49.75.01 28.88-23.93 52.36-53.33 52.36z"
                class=""
              ></path>
            </svg>
            <h6 class="font-weight-bold pt-3">Goed voor het milieu</h6>
            <p class="text-muted m-0">
              <small>
                Groene stroom heeft geen CO-2 uitstoot, dus je draagt bij aan
                een beter milieu!
              </small>
            </p>
            <br />
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "WaaromZonnepanelen",
};
</script>

<style>
.font-weight-bold {
  font-weight: 700 !important;
}
.sgv--color {
  color: #759e62 !important;
}
.svg-sizing {
  height: 1em;
  vertical-align: -0.125em;
  font-size: 4em;
}
</style>
