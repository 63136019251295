var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-container',{staticClass:"py-md-5"},[_c('b-row',{attrs:{"cols":" py-0 py-md-4"}},[_c('div',{staticClass:"col-12 col-md-6 col-lg-6"},[_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("../assets/logo.png")}})]),_c('h3',{staticClass:"font-weight-bold m-0"},[_vm._v(" Goed nieuws! Jouw woning "),_c('span',{staticClass:"text-secondary"},[_vm._v(" Lupineberg "+_vm._s(_vm.first_form.house_number))]),_vm._v(" in "),_c('span',{staticClass:"text-secondary"},[_vm._v("Roosendaal ")]),_vm._v(" is geschikt voor zonnepanelen. ")]),_c('div',{staticClass:"my-2 my-md-4"},[_c('div',[_c('GmapMap',{staticStyle:{"width":"100%","height":"350px"},attrs:{"options":{
                zoomControl: false,
                fullscreenControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: true,
                disableDefaultUi: false,
              },"center":{ lat: this.lat, lng: this.lng },"zoom":16,"map-type-id":"terrain"}},[_c('GmapMarker',{attrs:{"position":{
                  lat: this.lat,
                  lng: this.lng,
                },"clickable":true,"draggable":true}})],1)],1)]),_c('h3',{staticClass:"text-center"},[_vm._v("Bespaar tot 30% in maar 2 minuten!")])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"bg-white shadow p-3 p-md-4 banner__form"},[_c('SecondForm')],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }