<template>
  <b-container class="py-4 my-5">
    <b-row v-if="step < 6">
      <b-col class="col-md-8">
        <div class="form">
          <!-- Progress Indicator -->
          <div class="progress">
            <div
              v-for="index in 5"
              :key="index"
              class="step"
              :class="{ completed: step >= index }"
            ></div>
          </div>
          <div v-if="step === 1">
            <FirstQuestion
              :firstQuestion="stepsDetail.firstQuestion"
              :step="step"
              @gotoStepTwo="updateStep"
            />
          </div>
          <div v-if="step === 2">
            <SecondQuestion
              :secondQuestion="stepsDetail.secondQuestion"
              :thirdQuestion="stepsDetail.thirdQuestion"
              :step="step"
              @gotoStepThree="updateStep"
            />
          </div>
          <div v-if="step === 3">
            <ThirdQuestion
              :thirdQuestion="stepsDetail.thirdQuestion"
              :step="step"
              :fifthQuestion="stepsDetail.fifthQuestion"
              @gotoStepFour="updateStep"
              @gotoStepFifth="updateStep"
              @gotoResult="updateStep"
            />
          </div>
          <div v-if="step === 4">
            <ForthQuestion
              :forthQuestion="stepsDetail.forthQuestion"
              :thirdQuestion="stepsDetail.thirdQuestion"
              :fifthQuestion="stepsDetail.fifthQuestion"
              :step="step"
              @gotoStepThree="updateStep"
              @gotoStepFifth="updateStep"
            />
          </div>
          <div v-if="step === 5">
            <FifthQuestion
              :fifthQuestion="stepsDetail.fifthQuestion"
              :thirdQuestion="stepsDetail.thirdQuestion"
              :step="step"
              @gotoResult="updateStep"
            />
          </div>
          <div class="d-flex justify-content-between">
            <div
              @click="previousStep"
              class="mx-3 cursor-pointer"
              v-if="stepsDetail.firstQuestion.show == false"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M15.1876 9H2.81262"
                  stroke="#86B37E"
                  stroke-width="1.92"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.87512 3.9375L2.81262 9L7.87512 14.0625"
                  stroke="#86B37E"
                  stroke-width="1.92"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p class="d-inline-block">Vorige vraag</p>
            </div>
            <div class="ml-auto">
              <img src="../../assets/Ellipse.png" width="30" />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-else class="form main-cont">
      <input type="checkbox" class="hidden-checkbox" v-model="bootCheck" />
      <ResultForm
        :userDetail="stepsDetail.resultForm"
        :house_number="stepsDetail.firstQuestion.house_number"
        @submit="onSubmit"
      />
    </b-row>
  </b-container>
</template>

<script>
import FirstQuestion from "./SecondFormQuestions/FirstQuestion.vue";
import SecondQuestion from "./SecondFormQuestions/SecondQuestion.vue";
import ThirdQuestion from "./SecondFormQuestions/ThirdQuestion.vue";
import ForthQuestion from "./SecondFormQuestions/ForthQuestion.vue";
import FifthQuestion from "./SecondFormQuestions/FifthQuestion.vue";

import ResultForm from "./ResultForm.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "SecondForm",
  components: {
    FirstQuestion,
    SecondQuestion,
    ThirdQuestion,
    ForthQuestion,
    FifthQuestion,
    ResultForm,
  },
  data() {
    return {
      bootCheck: false,
      step: 1,
      loader: false,
      stepsDetail: {
        firstQuestion: {
          postCode: "",
          house_number: "",
          show: true,
        },
        secondQuestion: {
          activeStep: 1,
          // selectedOption: {
          // ownHome: "",
          // becomeOwner: "",
          // },
          ownHome: "",
        },
        thirdQuestion: {
          activeStep: 1,
          // selectedOption: {
          //   roofType: "",
          // },
          roofType: "",
          loader: false,
        },
        forthQuestion: {
          houseBuilt: "",
        },
        fifthQuestion: {
          activeStep: true,
          roof: "",
          frame: "",
        },
        resultForm: {
          isolateTime: "",
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          loader: false,
          leadResponse: false,
        },
      },
      valueMap: {
        ["4579"]: "4418",
        ["4563"]: "4409",
        ["4576"]: "4415",
        ["4566"]: "4412",
      },
    };
  },
  methods: {
    ...mapActions(["postZonnepanelenLead", "closeSecondForm"]),
    pushToAnswers(key, value) {
      this.stepsDetail.answers.push({ key, value });
    },
    previousStep() {
      if (this.step === 1) {
        if (this.stepsDetail.firstQuestion.show === false) {
          this.stepsDetail.firstQuestion.show = true;
        }
      } else if (this.step === 2) {
        if (this.stepsDetail.secondQuestion.activeStep === 1) {
          this.step = 1;
        } else if (this.stepsDetail.secondQuestion.activeStep === 2) {
          this.stepsDetail.secondQuestion.activeStep = 1;
        } else if (this.stepsDetail.secondQuestion.activeStep === 3) {
          this.stepsDetail.secondQuestion.activeStep = 2;
        }
        // if (this.stepsDetail.thirdQuestion.activeStep === 1) {
        //   console.log("step 3 1");
        //   this.stepsDetail.secondQuestion.activeStep = 1;
        // }
      } else if (this.step === 3) {
        if (this.stepsDetail.thirdQuestion.activeStep === 1) {
          console.log("here1");
          //this.step = 2;
          this.stepsDetail.secondQuestion.activeStep = 1;
          this.updateStep(2);
          //
        } else if (this.stepsDetail.thirdQuestion.activeStep === 2) {
          this.stepsDetail.thirdQuestion.activeStep = 1;
        } else if (this.stepsDetail.thirdQuestion.activeStep === 3) {
          this.step = 4;
        }
      } else if (this.step === 4) {
        this.stepsDetail.thirdQuestion.activeStep = 1;
        this.step = 3;
      } else if (this.step === 5) {
        if (this.stepsDetail.fifthQuestion.activeStep == false) {
          if (this.stepsDetail.thirdQuestion.selectedOption.includes("4409")) {
            this.stepsDetail.fifthQuestion.activeStep = true;
          } else {
            this.step = 4;
          }
        } else {
          this.stepsDetail.thirdQuestion.activeStep = 3;
          this.step = 3;
        }
      }
    },
    // backStep() {
    //   this.step = 2;
    // },

    updateStep(newStep) {
      console.log("new stp", newStep);
      this.step = newStep;
    },
    async onSubmit() {
      this.stepsDetail.resultForm.loader = true;
      let answers = [
        "4584",
        this.stepsDetail.secondQuestion.ownHome,
        this.stepsDetail.thirdQuestion.roofType,
        this.stepsDetail.forthQuestion.houseBuilt,
        this.stepsDetail.resultForm.isolateTime,
      ];
      const phoneNumberWithoutPunctuation =
        this.stepsDetail.resultForm.phoneNumber
          .replace(/[\s-]/g, "")
          .replace(/^00(\d+)$/, "$1");
      let form = {
        language: "nl_NL",
        site_custom_name: "Offertevergelijker_zonnepanelen",
        site_custom_url:
          "https://offertevergelijker.verbeterthuis.nl/zonnepanelen",
        firstname: this.stepsDetail.resultForm.firstName,
        lastname: this.stepsDetail.resultForm.lastName,
        email: this.stepsDetail.resultForm.email,
        street: this.address?.street || "",
        city: this.address?.settlement || "",
        house_number: this.stepsDetail?.firstQuestion?.house_number,
        zip: this.stepsDetail.firstQuestion.postCode,
        phone_number: this.stepsDetail.resultForm.phoneNumber,
        site_subid: this.$route.query.clickid,
        publisher_id: this.$route.query.site,
      };

      const isBuitenkant = answers.includes("4590");

      console.log("Form ,,,.. answers...", answers, form);
      if (isBuitenkant) {
        console.log(answers, "answer>>>>");
        this.stepsDetail.resultForm.loader = false;
        this.stepsDetail.resultForm.leadResponse = true;
      } else {
        const filteredOptions = answers.filter((option) => option !== "");
        let resp = await this.postZonnepanelenLead({
          ...form,
          bootCheck: this.bootCheck,
          answers: filteredOptions,
          site_subid: this.$route?.query?.clickid || "",
          publisher_id: this.$route?.query?.site || "",
          phone_number: phoneNumberWithoutPunctuation,
        });
        this.stepsDetail.resultForm.loader = false;

        if (resp.success == true || resp.statusCode === 409) {
          this.stepsDetail.resultForm.leadResponse = true;
        }
        if (
          this.getErrorResponses.zip == false &&
          this.getErrorResponses.housenumber == false
        ) {
          console.log("secondform redirection");

          await this.closeSecondForm();
          return;
        }
      }
    },
  },
  //   watch: {
  //     "secondQuestion.selectedOption.ownHome": function (newVal, oldVal) {
  //       console.log("Own Home changed from", oldVal, "to", newVal);
  //     },
  //   },
  // watch: {
  //   "stepsDetail.secondQuestion.selectedOption.ownHome": {
  //     handler: function (newVal, oldVal) {
  //       if (newVal == "4394") {
  //         this.stepsDetail.secondQuestion.selectedOption.becomeOwner = "";
  //         this.stepsDetail.thirdQuestion.insulateRoof = "";
  //         this.stepsDetail.secondQuestion.activeStep = 1;
  //         this.stepsDetail.thirdQuestion.activeStep = 1;
  //       } else {
  //         this.stepsDetail.thirdQuestion.selectedOption = [];
  //         this.stepsDetail.thirdQuestion.insulateRoof = "";
  //         this.stepsDetail.secondQuestion.activeStep = 1;
  //         this.stepsDetail.thirdQuestion.activeStep = 1;
  //       }
  //       console.log("Own Home changed from", oldVal, "to", newVal);
  //     },
  //     deep: true,
  //   },

  //   "stepsDetail.secondQuestion.activeStep": {
  //     handler: function (newVal, oldVal) {
  //       window.scrollTo(0, 0);
  //     },
  //     deep: true,
  //   },
  //   "stepsDetail.thirdQuestion.activeStep": {
  //     handler: function (newVal, oldVal) {
  //       window.scrollTo(0, 0);
  //     },
  //     deep: true,
  //   },
  //   "stepsDetail.thirdQuestion.selectedOption": {
  //     handler: function (newVal, oldVal) {
  //       if (!this.stepsDetail.thirdQuestion.selectedOption.includes("4409")) {
  //         (this.stepsDetail.thirdQuestion.insulateRoof = ""),
  //           (this.stepsDetail.fifthQuestion.roof = "");
  //       }
  //       if (!this.stepsDetail.thirdQuestion.selectedOption.includes("4412")) {
  //         this.stepsDetail.fifthQuestion.frame = "";
  //       }
  //     },
  //     deep: true,
  //   },
  //   step: {
  //     handler: function (newVal, oldVal) {
  //       window.scrollTo(0, 0);
  //     },
  //     deep: true,
  //   },
  // },

  computed: {
    ...mapGetters(["lat", "lng", "getErrorResponses", "address", "profId"]),
  },
};
</script>
<style lang="scss" scoped>
.form {
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.progress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  margin-bottom: 20px;
  background-color: #ffffff;
}

.step {
  width: 100%;
  height: 7px;
  border: 2px solid #ccc;
  margin-left: 10px !important;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 12px;

  &.completed {
    background-color: #3d99cd;
    border-color: #3d99cd;
  }
}

.input {
  background-color: #fcfcfc;
  border: 1px solid #e9eef3;
  border-radius: 10px;
  padding: 15px;
  width: 98%;
}

.title {
  color: #86b37e !important;
}

.options {
  margin-top: 20% !important;
}
</style>
