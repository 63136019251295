<template>
  <div class="banner">
    <SecondFormSection v-if="secondFormShow" />
    <!--  -->
    <b-container v-else class="p-md-5">
      <b-row cols="px-0 px-md-5 py-0 py-md-4">
        <div class="col-12 col-md-7 col-lg-6">
          <a href="#"><img src="../assets/logo.png" /></a>
          <h3 class="font-weight-bold pt-3">
            Zonnepanelen voor een vast laag bedrag per maand, zonder eigen
            investering ? Het kan!
          </h3>
          <h5>Doe hier onder de postcode check.</h5>
          <FirstForm />
        </div>
        <!--  -->
        <div class="col-12 col-md-6 align-self-center">
          <div class="bg-white shadow p-3 p-md-4 banner__ul my-5 my-md-0">
            <ul class="banner__ul__styling">
              <li class="banner__ul__text">
                <i class="fa-solid fa-check banner__icoColor"></i> Direct
                besparen op je energierekening
              </li>
              <li class="banner__ul__text">
                <i class="fa-solid fa-check banner__icoColor"></i> Geen grote
                investering noding
              </li>
              <li class="banner__ul__text">
                <i class="fa-solid fa-check banner__icoColor"></i> Verhuurder
                blijft verantwoordelikj voor onderhoud en reparatie
              </li>
              <li class="banner__ul__text">
                <i class="fa-solid fa-check banner__icoColor"></i> Panelen
                kunnen worden overgekocht
              </li>
              <li class="banner__ul__text">
                <i class="fa-solid fa-check banner__icoColor"></i> Positieve
                invloed op het energielabel van jouw woning
              </li>
              <li class="banner__ul__text">
                <i class="fa-solid fa-check banner__icoColor"></i> Meer dan
                <span class="banner__icoColor">109.000 </span>mensen gingen je
                voor
              </li>
            </ul>
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FirstForm from "../components/FirstForm.vue";
import SecondFormSection from "./SecondFormSection.vue";
export default {
  name: "Banner",
  components: { FirstForm, SecondFormSection },
  computed: mapGetters(["secondFormShow"]),
};
</script>

<style lang="scss" scoped>
.font-weight-bold {
  font-weight: 700 !important;
}
.banner {
  &__ul {
    border-radius: 8px !important;
    border-left: 2px solid #eee !important;
    border-color: #74a200 !important;
  }
  &__icoColor {
    color: #74a200;
  }
  &__ul__styling {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  &__ul__text {
    font-weight: 700;
    font-size: 15px;
  }
}
</style>
